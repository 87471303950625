import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from "./LoginButton";
import "../stylesheets/App.css";


const AuthComponent = (props) => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return (
        <div className="OverlayApp">
        <form className="overlayform">
        <h2>Loading...</h2>
        </form>
        </div>
    )
  }

  return (
    isAuthenticated ? (
      props.children
  ) : (
      <div id="App" className="App">
      <div id="logoContainer">
          <div id="logo">
          </div>
      </div>
      <div className="OverlayApp">
      <form className="overlayform">
      <h2>Let's get started!</h2>
      <LoginButton />
      </form>
      </div>
      </div>

  )
  );
};

export default AuthComponent;
