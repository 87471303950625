import React from "react";
import { slide as Menu } from "react-burger-menu";
import LogoutButton from "./LogoutButton";


var styles = {
    bmBurgerButton: {
        zIndex: 1000,
        position: "fixed",
        width: "30px",
        height: "24px",
        left: "36px",
        top: "10px"
    },
  // bmBurgerBars: {
  //   background: '#373a47'
  // },
  // bmBurgerBarsHover: {
  //   background: '#a90000'
  // },
  // bmCrossButton: {
  //   height: '24px',
  //   width: '24px'
  // },
  // bmCross: {
  //   background: '#bdc3c7'
  // },
  // bmMenuWrap: {
  //   position: 'fixed',
  //   height: '100%'
  // },
  // bmMenu: {
  //   background: '#373a47',
  //   padding: '2.5em 1.5em 0',
  //   fontSize: '1.15em'
  // },
  // bmMorphShape: {
  //   fill: '#373a47'
  // },
  // bmItemList: {
  //   color: '#b8b7ad',
  //   padding: '0.8em'
  // },
  // bmItem: {
  //   display: 'inline-block'
  // },
  // bmOverlay: {
  //   background: 'rgba(0, 0, 0, 0.3)'
  // }
}

export default props => {
  return (
    // Pass on our props
    <Menu itemListElement="div" styles={styles} left {...props}>
      <div className="menu-item">
        <LogoutButton />
      </div>
    </Menu>
  );
};
