import React, {Component} from 'react';
import { SketchPicker } from 'react-color';
import MonacoEditor from 'react-monaco-editor';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import Fab from "@material-ui/core/Fab";
// import ObjectTypes from './FlowHelper';
import _ from 'lodash';
// import Modal from 'react-modal';

//
// const customStyles = {
//   content : {
//     top                   : '50%',
//     left                  : '50%',
//     right                 : 'auto',
//     bottom                : 'auto',
//     marginRight           : '-50%',
//     transform             : 'translate(-50%, -50%)',
//     minWidth              : '300px',
//     zIndex                : 10000
//   }
// };




class StyleEditor extends Component {
    constructor(props){
        super(props);
        this.state = {
            showObjectTypesEditor: [],
            showObjectTypesOverrideEditor: [],
            modalIsOpen: false,
            modalType: 'preset',
            modalLabel: 'Save Preset',
            modalTextValue: '',
            background: '#fff',
            connectionType: "smoothstep",
            animated: false,
            arrowhead: 'arrowclosed',
            labelBgPaddingX: 8,
            labelBgPaddingY: 4,
            labelBgBorderRadius: 4,
            horizPadding: 200,
            vertPadding: 200,
            showLabelBackgroundStyleEditor: false,
            showLabelStyleEditor: false,
            showConnectionStyleEditor: false,
            showOverrideEditor: false,
            presets: [],
            themes: [],
            objecttypes: [],
            selectedPreset: {},
            selectedTheme: {},
            style: {
                stroke: "black"
            },
            labelBgStyle: {
                fill: '#FFCC00',
                color: '#fff',
                fillOpacity: 0.7
            },
            labelStyle: {
                fill: 'blue',
                fontWeight: 700
            },
            ObjectTypes: {},
            ObjectTypeOverride: {
                "labelStyle": {
                    "fill": "#f6ab6c",
                    "fontWeight": 700
                },
                "className": "nodeBack",
                "style": {
                    "padding": 10,
                    "backgroundColor": "transparent",
                    "color": "black",

                    "backgroundSize": "cover",
                    "backgroundRepeat": "no-repeat",
                    "backgroundPosition": "center",
                    "border": "hidden",
                    "height": "50px",
                    "width": "100px"
                }
            },
            override: false,

        };
        this.handleConnectionStyleChange = this.handleConnectionStyleChange.bind(this)
        this.handleOverrideSelection = this.handleOverrideSelection.bind(this)
        this.handleConnectionTypeChange = this.handleConnectionTypeChange.bind(this)
        this.handleAnimatedChange = this.handleAnimatedChange.bind(this)
        this.handleArrowheadChange = this.handleArrowheadChange.bind(this)
        this.handleLabelBgPaddingXChange = this.handleLabelBgPaddingXChange.bind(this)
        this.handleLabelBgPaddingYChange = this.handleLabelBgPaddingYChange.bind(this)
        this.handleLabelBgBorderRadiusChange = this.handleLabelBgBorderRadiusChange.bind(this)
        this.handleLabelBgStyleChange = this.handleLabelBgStyleChange.bind(this)
        this.handleLabelStyleChange = this.handleLabelStyleChange.bind(this)
        this.buttonClick = this.buttonClick.bind(this)
        this.handleHorizontalPaddingChange = this.handleHorizontalPaddingChange.bind(this)
        this.handleVerticalPaddingChange = this.handleVerticalPaddingChange.bind(this)
        this.renderObjectTypes = this.renderObjectTypes.bind(this)
        this.prepareData = this.prepareData.bind(this)
        this.handlePresetChange = this.handlePresetChange.bind(this)
        this.handlePresetApply = this.handlePresetApply.bind(this)
        this.handleThemeChange = this.handleThemeChange.bind(this)
        this.handleThemeApply = this.handleThemeApply.bind(this)
        this.handlePresetCreate = this.handlePresetCreate.bind(this)
        this.handleThemeCreate = this.handleThemeCreate.bind(this)
        this.handleModalTextChange = this.handleModalTextChange.bind(this)
        this.handleRenameSequence = this.handleRenameSequence.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.toggleShowObjectTypesEditor = this.toggleShowObjectTypesEditor.bind(this)
        this.toggleShowObjectTypesOverrideEditor = this.toggleShowObjectTypesOverrideEditor.bind(this)
        this.handleToggle = this.handleToggle.bind(this)

    }
    handleToggle(key){
        this.setState({
            [key]: !this.state[key]
        })
    }
    toggleShowObjectTypesEditor(key){
        this.setState({
            showObjectTypesEditor: {...this.state.showObjectTypesEditor, [key]: !this.state.showObjectTypesEditor[key]}
        })
    }
    toggleShowObjectTypesOverrideEditor(key){
        this.setState({
            showObjectTypesOverrideEditor: {...this.state.showObjectTypesOverrideEditor, [key]: !this.state.showObjectTypesOverrideEditor[key]}
        })
    }
    // shouldComponentUpdate(nextProps, nextState){
    //
    // }
    componentWillReceiveProps(nextProps) {
        if (nextProps.presets !== this.state.presets){
            if (nextProps.presets.length > 0){
                this.setState({
                    presets: nextProps.presets,
                    selectedPreset: nextProps.presets[0].id
                })
            }

        }
        if (nextProps.themes !== this.state.themes){
            if (nextProps.themes.length > 0){
                this.setState({
                    themes: nextProps.themes,
                    selectedTheme: nextProps.themes[0].id
                })
            }

        }

        if (nextProps.sequence !== this.state.sequence){
            let styleConfig = Object.keys(nextProps.sequence).length > 0 ? nextProps.sequence.style_json : {}
            let ObjectTypes = Object.keys(nextProps.sequence).length > 0 ? this.toMap(nextProps.sequence.object_types_json) : this.toMap(nextProps.objecttypes)
            if (Object.keys(styleConfig).length > 0){
                this.setState({
                    animated: styleConfig.animated_connections,
                    arrowhead: styleConfig.arrowhead_type,
                    background: styleConfig.background_color,
                    connectionType: styleConfig.connection_type,
                    horizPadding: parseFloat(styleConfig.horizontal_padding),
                    vertPadding: parseFloat(styleConfig.vertical_padding),
                    labelBgBorderRadius: styleConfig.label_background_border_radius,
                    labelBgPaddingX: styleConfig.label_background_padding_x,
                    labelBgPaddingY: styleConfig.label_background_padding_y,
                    labelBgStyle: styleConfig.label_background_style,
                    labelStyle: styleConfig.label_style,
                    style: styleConfig.style,
                    ObjectTypes: ObjectTypes,
                    showObjectTypesEditor: this.configureObjectTypesEditorState(ObjectTypes),
                    sequence: nextProps.sequence
                }, ()=>{

                    this.prepareData();
                })
            }
            else {
                // this.props.handleStyleChange(this.state);
            }
        }
        if (nextProps.objecttypes !== this.state.objecttypes){
            if (nextProps.objecttypes.length > 0){
                this.setState({
                    objecttypes: nextProps.objecttypes
                }, ()=>{
                    if (Object.keys(this.state.ObjectTypes).length == 0){
                        this.setState({
                            ObjectTypes: this.toMap(this.state.objecttypes)
                        })
                    }
                })
            }

        }
    }

    prepareData(){
        // console.log("prepareData:")
        // console.log(this.state.labelBgStyle)
        // console.log(this.state.labelStyle)
        // console.log(this.state.style)
        let labelBgStyle = this.state.labelBgStyle;
        let labelStyle = this.state.labelStyle;
        let style = this.state.style;
        let ObjectTypes = _.map(this.state.ObjectTypes, (val, key) => {
            if (typeof(val) == "string"){
                val = JSON.parse(val)
            }
            return val
        })
        if (typeof(labelBgStyle) == "string"){
            labelBgStyle = JSON.parse(labelBgStyle)
        }
        if (typeof(labelStyle) == "string"){
            labelStyle = JSON.parse(labelStyle)
        }
        if (typeof(style) == "string"){
            style = JSON.parse(style)
        }

        let data = _.omit({...this.state, ...{
            labelBgStyle: labelBgStyle,
            labelStyle: labelStyle,
            style: style,
            ObjectTypes: this.toMap(ObjectTypes)
        }}, ["ObjectTypeOverride", "override"])

        // console.log("handleStyleChange:")
        // console.log(data)



        this.props.handleStyleChange(data);

    }

    toMap(objects){
        let objectsMap = {};
        for (var i in objects){
            let obj = objects[i];
            objectsMap[obj.name] = obj
        }
        return objectsMap
    }

    fromMap(objects){
        let objectsList = _.map(objects, (val, key) =>{
            return val;
        });
        // for (var i in objects){
        //     let obj = objects[i];
        //     objectsMap[obj.name] = obj
        // }
        // _.each()
        return objectsList
    }


    configureObjectTypesEditorState(object_types){
        let editorState = {};
        _.each(Object.keys(object_types), (key)=> {
            editorState[key] = false;
        })
        return editorState;
    }


    componentDidMount(){
        // Modal.setAppElement('#controlPanel')
        let styleConfig = Object.keys(this.props.sequence).length > 0 ? this.props.sequence.style_json : {}
        let ObjectTypes = Object.keys(this.props.sequence).length > 0 ? this.toMap(this.props.sequence.object_types_json) : this.toMap(this.props.objecttypes)

        if (Object.keys(styleConfig).length > 0){
            this.setState({
                presets: this.props.presets,
                themes: this.props.themes,
                objecttypes: this.props.objecttypes,
                selectedTheme: this.props.themes[0].id,
                selectedPreset: this.props.presets[0].id,
                animated: styleConfig.animated_connections,
                arrowhead: styleConfig.arrowhead_type,
                background: styleConfig.background_color,
                connectionType: styleConfig.connection_type,
                horizPadding: parseFloat(styleConfig.horizontal_padding),
                vertPadding: parseFloat(styleConfig.vertical_padding),
                labelBgBorderRadius: styleConfig.label_background_border_radius,
                labelBgPaddingX: styleConfig.label_background_padding_x,
                labelBgPaddingY: styleConfig.label_background_padding_y,
                labelBgStyle: styleConfig.label_background_style,
                labelStyle: styleConfig.label_style,
                style: styleConfig.style,
                ObjectTypes: ObjectTypes,
                showObjectTypesEditor: this.configureObjectTypesEditorState(ObjectTypes),
                sequence: this.props.sequence
            }, ()=>{
                // this.props.handleStyleChange(this.state);
                this.prepareData();
                // this.props.handleStyleChange(_.omit(this.state, ["ObjectTypeOverride", "override"]));
            })
        }
        else {
            // this.props.handleStyleChange(this.state);
            this.prepareData();
            // this.props.handleStyleChange(_.omit(this.state, ["ObjectTypeOverride", "override"]));
        }


    }

    buttonClick(){
        // this.props.handleStyleChange(this.state);
        console.log("buttonClick Style")
        this.prepareData();
        // this.props.handleStyleChange(_.omit(this.state, ["ObjectTypeOverride", "override"]));
    }

    handleModalTextChange(evt) {
        this.setState({
            modalTextValue: evt.target.value
        })
    }

    handleHorizontalPaddingChange(evt) {
        this.setState({
            horizPadding: evt
        }, ()=>{

        })
    }

    handleVerticalPaddingChange(evt) {
        this.setState({
            vertPadding: evt
        }, ()=>{

        })
    }

    handleConnectionStyleChange(evt) {
        this.setState({
            style: evt
        })
    }

    handleLabelStyleChange(evt) {
        this.setState({
            labelStyle: evt
        }, ()=>{

        })
    }

    handleLabelBgStyleChange(evt) {
        this.setState({
            labelBgStyle: evt
        }, ()=>{
            // this.props.handleStyleChange(this.state);
        })
    }

    handleLabelBgBorderRadiusChange(evt) {
        this.setState({
            labelBgBorderRadius: evt
        }, ()=>{
            // this.props.handleStyleChange(this.state);
        })
    }

    handleChangeComplete = (color) => {
        this.setState({ background: color.hex }, ()=>{
            // this.props.handleStyleChange(this.state);
        });

    };

    handleConnectionTypeChange(evt) {
        this.setState({
            connectionType: evt.target.value
        }, ()=>{
            // this.props.handleStyleChange(this.state);
        })
    }

    handleAnimatedChange(evt) {
        this.setState({
            animated: evt.target.value
        }, ()=>{
            // this.props.handleStyleChange(this.state);
        })
    }


    handleArrowheadChange(evt) {
        this.setState({
            arrowhead: evt.target.value
        }, ()=>{
            // this.props.handleStyleChange(this.state);
        })
    }


    handleLabelBgPaddingXChange(evt) {
        this.setState({
            labelBgPaddingX: evt.target.value
        }, ()=>{
            // this.props.handleStyleChange(this.state);
        })
    }

    handleLabelBgPaddingYChange(evt) {
        this.setState({
            labelBgPaddingY: evt.target.value
        }, ()=>{
            // this.props.handleStyleChange(this.state);
        })
    }

    handleObjectChange(evt, key) {
        // this.setState({
        //     ObjectTypes: {...this.state.ObjectTypes, [key]:evt.target.value}
        // }, ()=>{
        //     // this.props.handleStyleChange(this.state);
        // })
        // console.log(evt)
        // console.log(key)
        this.setState(prevState => ({
            ObjectTypes: {
                ...prevState.ObjectTypes,
                // [key]: JSON.parse(evt.target.value),
                [key]: evt,
            },
        }));
    }

    handleObjectOverrideChange(evt) {
        this.setState({
            ObjectTypeOverride: evt
        }, ()=>{
            // this.props.handleStyleChange(this.state);
        })

        // this.setState(prevState => ({
        //     ObjectTypes: {
        //         ...prevState.ObjectTypes,
        //         [key]: JSON.parse(evt.target.value),
        //     },
        // }));
    }

    editorDidMount(editor, monaco) {
      // console.log('editorDidMount', editor);
      editor.focus();
    }


    renderObjectTypes(){
        const options = {
          selectOnLineNumbers: true,
          tabSize: 2
        };
        return _.map(this.state.ObjectTypes, (val, key) => {

            // let backgroundImage = this.state.ObjectTypes[key]["style"]["backgroundImage"]
            // let params = {...this.state.ObjectTypes[key]}

            // if (this.state.override){
            //     params = {...this.state.ObjectTypeOverride, style: {
            //         ...this.state.ObjectTypeOverride.style, backgroundImage: backgroundImage
            //     }}
            //
            //
            //
            // }

            let v = val;
            // console.log("val:")
            // console.log(val);
            //
            // console.log("val type:")
            // console.log(typeof(val))

            if (typeof(v) !== 'string'){
                v = JSON.stringify(val, null, 4)
            }

            let style = {};

            try{
                style = JSON.parse(v)["style"];
            }
            catch(err){
                console.log(err);
            }



            return (
                <div key={key}>
                <div className="StyleEditorRow">
                <div className="styleItem">
                <h5 style={{color: "white"}}>
                    {key}
                </h5>
                <div style={style} />
                </div>
                </div>
                <div className="StyleEditorRow">
                <div className="styleItem">

                {/*
                    <textarea rows={20} cols={80} value={JSON.stringify(params, null, 4)} onChange={(evt) => this.handleObjectChange(evt, key)} />
                */}
                <Button variant="contained" color="primary" onClick={()=>{this.toggleShowObjectTypesEditor(key)}}>Toggle Editor</Button>
                {this.state.showObjectTypesEditor[key] && (
                    <MonacoEditor
                      width="800"
                      height="600"
                      language="json"
                      theme="myCoolTheme"
                      value={v}
                      options={options}
                      onChange={(evt) => this.handleObjectChange(evt, key)}
                      editorDidMount={this.editorDidMount}
                    />
                )}




                </div>
                </div>
                </div>
            )
        })
    }

    renderObjectTypesSimple(){
        const options = {
          selectOnLineNumbers: true,
          tabSize: 2
        };
        return _.map(this.state.ObjectTypes, (val, key) => {

            // let backgroundImage = this.state.ObjectTypes[key]["style"]["backgroundImage"]
            // let params = {...this.state.ObjectTypes[key]}

            // if (this.state.override){
            //     params = {...this.state.ObjectTypeOverride, style: {
            //         ...this.state.ObjectTypeOverride.style, backgroundImage: backgroundImage
            //     }}
            //
            //
            //
            // }

            let v = val;
            // console.log("val:")
            // console.log(val);
            //
            // console.log("val type:")
            // console.log(typeof(val))

            if (typeof(v) !== 'string'){
                v = JSON.stringify(val, null, 4)
            }

            let style = {};

            try{
                style = JSON.parse(v)["style"];
            }
            catch(err){
                console.log(err);
            }



            return (
                <div key={key}>
                <div className="StyleEditorRow">
                <div className="styleItem">
                <h5 style={{color: "white"}}>
                    {key}
                </h5>
                <div style={style} />
                </div>
                </div>
                <div className="StyleEditorRow">
                <div className="styleItem">


                {/*
                    <textarea rows={20} cols={80} value={JSON.stringify(params, null, 4)} onChange={(evt) => this.handleObjectChange(evt, key)} />
                */}


                </div>
                </div>
                </div>
            )
        })
    }

    handleOverrideSelection(evt) {
        this.setState({
            override: JSON.parse(evt.target.value),

        }, () => {
            if (this.state.override){
                let keys = Object.keys(this.state.ObjectTypes);
                let ObjectTypeOverride = this.state.ObjectTypeOverride;
                if (typeof(ObjectTypeOverride) === "string"){
                    ObjectTypeOverride = JSON.parse(ObjectTypeOverride)
                }
                // let objTypesList = _.map(keys, (k) => {
                //     let v = this.state.ObjectTypes[k];
                //     return {
                //                 ...this.state.ObjectTypes,
                //                 style: {
                //                     ...ObjectTypeOverride.style,
                //                     backgroundImage: v.style.backgroundImage
                //                 },
                //                 labelStyle: {
                //                     ...ObjectTypeOverride.labelStyle
                //                 }
                //             }
                // })
                // console.log("objTypesList:");
                // console.log(objTypesList);
                // let objTypes = _.reduce(objTypesList, (obj, param, i) => {
                //     obj[keys[i]] = param
                //     return obj
                // }, {})
                //
                // console.log("objTypes:");
                // console.log(objTypes);
                let objTypes = this.toMap(_.map(this.state.ObjectTypes, (val, key)=>{
                    // let v = val;
                        return {
                                    ...val,
                                    style: {
                                        ...ObjectTypeOverride.style,
                                        backgroundImage: val.style.backgroundImage
                                    },
                                    labelStyle: {
                                        ...ObjectTypeOverride.labelStyle
                                    }
                                }
                }))
                this.setState({
                    lastObjectTypes: this.state.ObjectTypes,
                    ObjectTypes: objTypes
                })
            }
            else {
                this.setState({

                    ObjectTypes: this.state.lastObjectTypes
                })
            }
            // console.log('override:', typeof(this.state.override))
        })
    }

    renderObjectTypeOverride(){
        const { classes } = this.props;
        const options = {
          selectOnLineNumbers: true,
          tabSize: 2
        };
        let v = this.state.ObjectTypeOverride;
        if (typeof(v) !== "string"){
            v = JSON.stringify(v, null, 4)
        }
        return (
            <div style={{margin: 0}} className="StyleEditorRow">
            <div style={{margin: 0}} className="StyleEditorRow">
            <div className="styleItem">
            <h3 style={{color: "white"}}>
              Override
            </h3>
            </div>
            </div>
            <div style={{margin: 0}} className="StyleEditorRow">
            <div className="styleItem">
            <FormControl style={{width: "100%"}} variant="filled" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">Use Override</InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    onChange={this.handleOverrideSelection}
                    value={this.state.override}
                    label="Override"
                  >
                  <MenuItem value={true}>{"True"}</MenuItem>
                  <MenuItem value={false}>{"False"}</MenuItem>

                  </Select>
              </FormControl>





            </div>
            </div>
            <div style={{margin: 0}} className="StyleEditorRow">
            <div style={{margin: 0}} className="styleItem">

            <Button variant="contained" color="primary" style={{marginTop: 10, marginBottom: 10}} onClick={()=>{this.handleToggle("showOverrideEditor")}}>Toggle Editor</Button>
            {this.state.showOverrideEditor && (
                <MonacoEditor
                  width="800"
                  height="600"
                  language="json"
                  theme="myCoolTheme"
                  value={v}
                  options={options}
                  onChange={(evt) => this.handleObjectOverrideChange(evt)}
                  editorDidMount={this.editorDidMount}
                />
            )}


            {/*
                <textarea rows={20} cols={80} value={JSON.stringify(this.state.ObjectTypeOverride, null, 4)} onChange={(evt) => this.handleObjectOverrideChange(evt)} />
            */}

            </div>
            </div>
            </div>
        )
    }

    handlePresetChange(evt) {

        this.setState({
            selectedPreset: evt.target.value
        })
    }

    handleThemeChange(evt) {
        // let theme = _.filter(this.state.themes, (theme) =>{
        //     return theme.id == evt.target.value
        // })[0]
        this.setState({
            selectedTheme: evt.target.value
        })
    }

    handlePresetApply(){
        // console.log("selectedPreset:")
        // console.log(this.state.selectedPreset)
        let preset = _.filter(this.state.presets, (preset) =>{
            return preset.id == this.state.selectedPreset
        })[0]
        // console.log("preset:")
        // console.log(preset)
        if (typeof(preset) == 'string'){
            preset = JSON.parse(preset)
        }
        this.setState({
            animated: preset.style.animated_connections,
            arrowhead: preset.style.arrowhead_type,
            background: preset.style.background_color,
            connectionType: preset.style.connection_type,
            horizPadding: parseFloat(preset.style.horizontal_padding),
            vertPadding: parseFloat(preset.style.vertical_padding),
            labelBgBorderRadius: preset.style.label_background_border_radius,
            labelBgPaddingX: preset.style.label_background_padding_x,
            labelBgPaddingY: preset.style.label_background_padding_y,
            labelBgStyle: preset.style.label_background_style,
            labelStyle: preset.style.label_style,
            style: preset.style.style
        })
    }

    handleThemeApply(){
        let theme = _.filter(this.state.themes, (theme) =>{
            return theme.id == this.state.selectedTheme
        })[0]
        if (typeof(theme) == 'string'){
            theme = JSON.parse(theme)
        }
        // console.log("theme:")
        // console.log(theme)
        this.setState({
            ObjectTypes: this.toMap(theme.object_types)
        })
    }

    async handlePresetCreate(){
        if (this.state.modalIsOpen){
            let data = {
                name: this.state.modalTextValue,
                style: {
                    animated_connections: this.state.animated,
                    arrowhead_type: this.state.arrowhead,
                    background_color: this.state.background,
                    connection_type: this.state.connectionType,
                    horizontal_padding: this.state.horizPadding,
                    vertical_padding: this.state.vertPadding,
                    label_background_border_radius: this.state.labelBgBorderRadius,
                    label_background_padding_x: this.state.labelBgPaddingX,
                    label_background_padding_y: this.state.labelBgPaddingY,
                    label_background_style: (typeof(this.state.labelBgStyle) == "string") ? JSON.parse(this.state.labelBgStyle) : this.state.labelBgStyle,
                    label_style: (typeof(this.state.labelStyle) == "string") ? JSON.parse(this.state.labelStyle) : this.state.labelStyle,
                    style: (typeof(this.state.style) == "string") ? JSON.parse(this.state.style) : this.state.style

                }
            }


            this.props.createPreset(data, "preset")
            this.setState({
                modalIsOpen: false
            })
        }
        else {
            this.setState({
                modalIsOpen: true,
                modalType: "preset",
                modalLabel: "Save Preset",
                modalTextValue: ""
            })
        }
    }

    async handleThemeCreate(){
        if (this.state.modalIsOpen){
            let objectTypes = this.toMap(_.map(this.state.ObjectTypes, (val, key) => {
                return _.omit(val, ["id", "resource_uri", "updated_at", "created"])
            }))
            let data = {
                name: this.state.modalTextValue,
                object_types: this.fromMap(objectTypes)
            }
            // await this.api.post(data, 'theme').then(
            //     ret => {
            //         console.log(ret)
            //     }
            // )
            this.props.createTheme(data, 'theme')
            this.setState({
                modalIsOpen: false
            })
        }
        else {
            this.setState({
                modalIsOpen: true,
                modalType: "theme",
                modalLabel: "Save Theme",
                modalTextValue: ""
            })
        }
    }

    async handleRenameSequence(){

    }

    afterOpenModal(){

    }
    closeModal(){
        this.setState({
            modalIsOpen: false
        })
    }




    render(){
        const { classes } = this.props;
        const options = {
          selectOnLineNumbers: true,
          tabSize: 2
        };

        return (



            <div id="StyleEditor" className="StyleEditor">
            {this.state.modalIsOpen && (
                <div className="saveModal">
                {this.state.modalType == "preset" && (
                    <div style={{margin: 0, backgroundColor: "black", justifyContent: "center", alignItems: "center"}} className="StyleEditorRow">
                    <div style={{flex: 1, alignItems: "center", backgroundColor: "black"}} className="styleItem">
                        <Button variant="outlined" color="primary" onClick={this.closeModal}>close</Button>
                    </div>
                    <div style={{flex: 2, alignItems: "center"}} className="styleItem">

                    <form style={{width: "100%"}} className={classes.root} noValidate autoComplete="off">
                    <TextField
                      value={this.state.modalTextValue}
                      onChange={this.handleModalTextChange}
                      id="standard-full-width"
                      label="Preset Name"
                      variant="filled"
                      color="secondary"
                      fullWidth

                    />
                    </form>
                    </div>

                    <div style={{flex: 1, alignItems: "center"}} className="styleItem">
                    <Button variant="outlined" color="primary" onClick={this.handlePresetCreate}>Create Preset</Button>
                    </div>
                     </div>
                )}
                {this.state.modalType == "theme" && (
                    <div style={{backgroundColor: "black",margin: 0, justifyContent: "center", alignItems: "center"}} className="StyleEditorRow">
                    <div style={{flex: 1, alignItems: "center", backgroundColor: "black"}} className="styleItem">
                        <Button variant="outlined" color="primary" onClick={this.closeModal}>close</Button>
                    </div>
                    <div style={{flex: 2, alignItems: "center"}} className="styleItem">

                    <form style={{width: "100%"}} className={classes.root} noValidate autoComplete="off">
                    <TextField
                      value={this.state.modalTextValue}
                      onChange={this.handleModalTextChange}
                      id="standard-full-width"
                      label="Theme Name"
                      variant="filled"
                      color="secondary"
                      fullWidth

                    />
                    </form>
                    </div>

                    <div style={{flex: 1, alignItems: "center"}} className="styleItem">
                    <Button variant="outlined" color="primary" onClick={this.handleThemeCreate}>Create Theme</Button>
                    </div>
                     </div>





                )}
                {this.state.modalType == "sequence" && (
                    <div style={{backgroundColor: "black", justifyContent: "center", alignItems: "center"}} className="StyleEditorRow">
                    <div style={{flex: 1, alignItems: "center", backgroundColor: "black"}} className="styleItem">
                        <Button variant="outlined" color="primary" onClick={this.closeModal}>close</Button>
                    </div>
                    <div style={{flex: 2, alignItems: "center"}} className="styleItem">

                    <form style={{width: "100%"}} className={classes.root} noValidate autoComplete="off">
                    <TextField
                      value={this.state.modalTextValue}
                      onChange={this.handleModalTextChange}
                      id="standard-full-width"
                      label="Sequence Name"
                      variant="filled"
                      color="secondary"
                      fullWidth

                    />
                    </form>
                    </div>

                    <div style={{flex: 1, alignItems: "center"}} className="styleItem">
                    <Button variant="outlined" color="primary" onClick={this.handleRenameSequence}>Rename Sequence</Button>
                    </div>
                     </div>


                )}
                </div>
            )}

            {/*
                <div style={{justifyContent: 'center'}} className="StyleEditorRow">
                <Button variant="outlined" color="primary" onClick={this.buttonClick}>Save</Button>
                </div>

            */}
            <Tabs>
                <TabList>
                    <Tab selectedClassName="selectedTab" style={{color: "white"}}>Global Styles</Tab>
                    <Tab selectedClassName="selectedTab" style={{color: "white"}}>Object Styles</Tab>
                </TabList>
                <TabPanel>
                <div style={{margin: 0}} className="StyleEditorRow">
                <div className="styleItem">
                <h3 style={{color: "white"}}>
                  Global Styles
                </h3>
                </div>
                <div style={{
                    backgroundColor: "dimgray",
                    margin: 0,
                    borderBottom: "gray 1px solid"
                }} className="StyleEditorRow">
                <div style={{flex: 1, alignItems: "center", flexDirection: "row"}} className="styleItem">


                 <div style={{flex: 1, alignItems: "center"}} className="styleItem">
                 <FormControl style={{width: "100%"}} variant="filled" className={classes.formControl}>
                   <InputLabel id="demo-simple-select-outlined-label">Presets</InputLabel>
                       <Select
                         labelId="demo-simple-select-filled-label"
                         id="demo-simple-select-filled"
                         onChange={this.handlePresetChange} value={this.state.selectedPreset}
                         label="Preset"
                       >
                       {_.map(this.state.presets, (preset)=>{
                           return(<MenuItem key={preset.id} value={preset.id}>{preset.name}</MenuItem>)

                       })}



                       </Select>
                   </FormControl>


                 </div>
                 </div>
                 <div style={{flex: 1, justifyContent: "center", alignItems: "center", flexDirection: "row"}} className="styleItem">
                 <div style={{ alignItems: "center"}} className="styleItem">
                 <Button variant="outlined" onClick={this.handlePresetApply}>Apply Preset</Button>
                 </div>
                 <div style={{alignItems: "center"}} className="styleItem">
                 <Button variant="outlined" onClick={this.handlePresetCreate}>Create Preset</Button>
                 </div>
                 </div>
                </div>



                <div style={{
                    borderBottom: "gray 1px solid",
                    alignItems: "center"
                }} className="StyleEditorRow">

                <div style={{flex: .5, alignItems: "center"}} className="styleItem">
                <h5 style={{color: "white"}}>
                    Background Color
                </h5>
                <SketchPicker
                    color={ this.state.background }
                    onChangeComplete={ this.handleChangeComplete }
                  />
                 </div>
                 <div style={{flex: 1, alignItems: "center", flexDirection: "row", alignItems: "flex-end"}} className="styleItem">
                 <div style={{alignItems: "flex-end"}} className="StyleEditorRow">
                 <div style={{flex: 1, alignItems: "center"}} className="styleItem">

                 <FormControl style={{width: "100%"}} variant="filled" className={classes.formControl}>
                   <InputLabel id="demo-simple-select-outlined-label">Connection Type</InputLabel>
                       <Select
                         labelId="demo-simple-select-filled-label"
                         id="demo-simple-select-filled"
                         onChange={this.handleConnectionTypeChange} value={this.state.connectionType}
                         label="Connection Type"
                       >
                       <MenuItem value="smoothstep">smoothstep</MenuItem>
                       <MenuItem value="step">step</MenuItem>
                       <MenuItem value="straight">straight</MenuItem>
                       <MenuItem value="custom">custom</MenuItem>

                       </Select>
                   </FormControl>



                  </div>
                  <div style={{flex: 1, alignItems: "center"}} className="styleItem">

                  <FormControl style={{width: "100%"}} variant="filled" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Animated</InputLabel>
                        <Select
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                          onChange={this.handleAnimatedChange} value={this.state.animated}
                          label="Animated"
                        >
                        <MenuItem value={true}>True</MenuItem>
                        <MenuItem value={false}>False</MenuItem>


                        </Select>
                    </FormControl>



                   </div>
                   <div style={{flex: 1, alignItems: "center"}} className="styleItem">

                   <FormControl style={{width: "100%"}} variant="filled" className={classes.formControl}>
                     <InputLabel id="demo-simple-select-outlined-label">Arrowhead Type</InputLabel>
                         <Select
                           labelId="demo-simple-select-filled-label"
                           id="demo-simple-select-filled"
                           onChange={this.handleArrowheadChange} value={this.state.arrowhead}
                           label="Arrowhead Type"
                         >
                         <MenuItem value="arrowclosed">arrowclosed</MenuItem>
                         <MenuItem value="arrow">arrow</MenuItem>


                         </Select>
                     </FormControl>



                    </div>
                    </div>
                    <div style={{alignItems: "flex-end"}} className="StyleEditorRow">
                    <div style={{flex: 1, alignItems: "center"}} className="styleItem">

                    <FormControl style={{width: "100%"}} component="fieldset">
                    <FormLabel style={{color: "white", padding: 5}} component="legend">Label Background Padding</FormLabel>
                    <TextField
                      value={this.state.labelBgPaddingX}
                      onChange={this.handleLabelBgPaddingXChange}
                      id="standard-full-width"
                      label="X Padding"
                      variant="filled"
                      color="secondary"
                      fullWidth

                    />
                    <TextField
                      value={this.state.labelBgPaddingY}
                      onChange={this.handleLabelBgPaddingYChange}
                      id="standard-full-width"
                      label="Y Padding"
                      variant="filled"
                      color="secondary"
                      fullWidth

                    />
                    </FormControl>


                    </div>

                     <div style={{flex: 1, alignItems: "center"}} className="styleItem">


                     <FormControl style={{width: "100%"}} component="fieldset">
                     <FormLabel style={{color: "white", padding: 5}} component="legend">Label Background Border Radius</FormLabel>
                     <TextField
                       value={this.state.labelBgBorderRadius}
                       onChange={this.handleLabelBgBorderRadiusChange}
                       id="standard-full-width"
                       label="Border Radius"
                       variant="filled"
                       color="secondary"
                       fullWidth

                     />

                     </FormControl>



                     </div>

                      </div>
                      <div  className="StyleEditorRow">
                      <div style={{flex: 1, alignItems: "center"}} className="styleItem">


                      <FormControl style={{width: "100%"}} component="fieldset">
                      <FormLabel style={{color: "white", padding: 5}} component="legend">Horizontal Padding</FormLabel>
                      <TextField
                        value={this.state.horizPadding}
                        onChange={this.handleHorizontalPaddingChange}
                        id="standard-full-width"
                        label="padding"
                        variant="filled"
                        color="secondary"
                        fullWidth

                      />

                      </FormControl>




                       </div>
                       <div style={{flex: 1, alignItems: "center"}} className="styleItem">
                       <FormControl style={{width: "100%"}} component="fieldset">
                       <FormLabel style={{color: "white", padding: 5}} component="legend">Vertical Padding</FormLabel>
                       <TextField
                         value={this.state.vertPadding}
                         onChange={this.handleVerticalPaddingChange}
                         id="standard-full-width"
                         label="padding"
                         variant="filled"
                         color="secondary"
                         fullWidth

                       />

                       </FormControl>



                        </div>

                      </div>
                    </div>
                    </div>


                      <div style={{
                          borderBottom: "gray 1px solid"
                      }} className="StyleEditorRow">

                      <div style={{flex: 1, alignItems: "center"}} className="styleItem">
                      <h5 style={{color: "white"}}>
                          Label Background Style
                      </h5>

                      <Button variant="contained" color="primary" style={{margin: 10}} onClick={()=>{this.handleToggle("showLabelBackgroundStyleEditor")}}>Toggle Editor</Button>
                      {this.state.showLabelBackgroundStyleEditor && (
                          <MonacoEditor
                            width="800"
                            height="200"
                            language="json"
                            theme="myCoolTheme"
                            value={(typeof(this.state.labelBgStyle) == "string") ? this.state.labelBgStyle : JSON.stringify(this.state.labelBgStyle, null, 4)}
                            options={options}
                            onChange={this.handleLabelBgStyleChange}
                            editorDidMount={this.editorDidMount}
                          />
                      )}


                      {/*
                          <textarea rows={8} cols={30} value={(typeof(this.state.labelBgStyle) == "string") ? this.state.labelBgStyle : JSON.stringify(this.state.labelBgStyle, null, 4)} onChange={this.handleLabelBgStyleChange} />
                      */}


                       </div>

                       <div style={{flex: 1, alignItems: "center"}} className="styleItem">
                       <h5 style={{color: "white"}}>
                           Label Style
                       </h5>
                       <Button variant="contained" color="primary" style={{margin: 10}} onClick={()=>{this.handleToggle("showLabelStyleEditor")}}>Toggle Editor</Button>
                       {this.state.showLabelStyleEditor && (
                           <MonacoEditor
                             width="800"
                             height="200"
                             language="json"
                             theme="myCoolTheme"
                             value={(typeof(this.state.labelStyle) == "string") ? this.state.labelStyle : JSON.stringify(this.state.labelStyle, null, 4)}
                             options={options}
                             onChange={this.handleLabelStyleChange}
                             editorDidMount={this.editorDidMount}
                           />
                       )}


                       {/*

                           <textarea rows={8} cols={30} value={(typeof(this.state.labelStyle) == "string") ? this.state.labelStyle : JSON.stringify(this.state.labelStyle, null, 4)} onChange={this.handleLabelStyleChange} />

                        */}


                        </div>
                        <div style={{flex: 1, alignItems: "center"}} className="styleItem">
                        <h5 style={{color: "white"}}>
                            Connection Style
                        </h5>
                        <Button variant="contained" color="primary" style={{margin: 10}} onClick={()=>{this.handleToggle("showConnectionStyleEditor")}}>Toggle Editor</Button>
                        {this.state.showConnectionStyleEditor && (
                            <MonacoEditor
                              width="800"
                              height="200"
                              language="json"
                              theme="myCoolTheme"
                              value={(typeof(this.state.style) == "string") ? this.state.style : JSON.stringify(this.state.style, null, 4)}
                              options={options}
                              onChange={this.handleConnectionStyleChange}
                              editorDidMount={this.editorDidMount}
                            />
                        )}

                        {/*

                            <textarea rows={8} cols={30} value={(typeof(this.state.style) == "string") ? this.state.style : JSON.stringify(this.state.style, null, 4)} onChange={this.handleConnectionStyleChange} />

                        */}


                         </div>
                        </div>
                        </div>
                        </TabPanel>
                        <TabPanel>



                    <div style={{

                        margin: 0
                    }} className="StyleEditorRow">
                    <div className="styleItem">
                    <h3 style={{color: "white"}}>
                      Object Styles
                    </h3>
                    </div>

                    <div style={{
                        backgroundColor: "dimgray",
                        margin: 0,
                        borderBottom: "gray 1px solid"
                    }} className="StyleEditorRow">
                    <div style={{flex: 1, alignItems: "center", flexDirection: "row"}} className="styleItem">

                    <div style={{flex: 1, alignItems: "center"}} className="styleItem">
                    <FormControl style={{width: "100%"}} variant="filled" className={classes.formControl}>
                      <InputLabel id="demo-simple-select-outlined-label">Themes</InputLabel>
                          <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            onChange={this.handleThemeChange} value={this.state.selectedTheme}
                            label="Theme"
                          >
                          {_.map(this.state.themes, (theme)=>{
                              return (<MenuItem key={theme.id} value={theme.id}>{theme.name}</MenuItem>)

                          })}



                          </Select>
                      </FormControl>


                    </div>
                    </div>
                    <div style={{flex: 1, justifyContent: "center", alignItems: "center", flexDirection: "row"}} className="styleItem">
                    <div style={{alignItems: "center"}} className="styleItem">
                    <Button variant="outlined" onClick={this.handleThemeApply}>Apply Theme</Button>
                     </div>
                     <div style={{alignItems: "center"}} className="styleItem">
                     <Button variant="outlined" onClick={this.handleThemeCreate}>Create Theme</Button>
                      </div>
                      </div>
                    </div>
                    </div>
                    <div style={{
                        borderBottom: "gray 1px solid"
                    }} className="StyleEditorRow">

                    {this.renderObjectTypeOverride()}
                 </div>
                    <div style={{
                        borderBottom: "gray 1px solid",
                        alignItems: "center"
                    }} className="StyleEditorRow">
                        {this.renderObjectTypes()}
                        </div>

                     </TabPanel>
                     </Tabs>
                    </div>


        );
    }
}

export default StyleEditor
