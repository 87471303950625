import React from 'react';
import ReactDOM from 'react-dom';
import './stylesheets/index.css';
import App from './App';
import reportWebVitals from './utils/reportWebVitals';
import 'fontsource-roboto';
import { Auth0Provider } from "@auth0/auth0-react";
import AuthComponent from "./components/AuthComponent"


ReactDOM.render(
  <React.StrictMode>
  <Auth0Provider
    domain="austinbrown.us.auth0.com"
    clientId="Gppwp8WjnS6RmUZ7DL3niHCq3uCwhaAI"
    redirectUri={window.location.origin}
  >
    <AuthComponent>
        <App />
    </AuthComponent>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
