import logo from "./media/logo.svg";
import "./stylesheets/App.css";
import MyLayoutFlow from "./components/MyLayoutFlow";
import React, { Component, useState } from "react";
import Api from "./utils/Api";
import _ from 'lodash';
import SideBar from "./components/Sidebar";
import ControlPanel from "./components/ControlPanel";
import ReactDetachableWindow from 'react-detachable-window'
import { withAuth0 } from '@auth0/auth0-react';
import WindowOpener from 'react-window-opener'
import { HashRouter, Route, Switch } from 'react-router-dom'
import {ResizableBox} from 'react-resizable'

console.log = () => {};


// function SmallWindow() {
//   const [state, setstate] = useState("")
//   const update = (evt) =>{
//
//     window.opener.onSuccess(state)
//     setstate(evt.target.value)
//   }
//     return (
//       <center>
//         <h1>
//             Hello World
//         </h1>
//         <p>Type anything here to see it updated in the parent</p>
//         <input
//           value={state}
//           onChange={update}
//         />
//         <br/><br/>
//         <button onClick={()=>{window.close()}}>Close Me</button>
//         </center>
//     )
// }

class App extends Component {

  constructor(props) {
    super(props);
    this.auth0 = this.props.auth0;
    this.JSONToolsRef = React.createRef();
    this.state = {
        width: 200,
        height: 200,
        focusNodeInput: -1,
      downloadLink: "",
      inputValue: [],
      outputValue: [],
      selectValue: "Editor",
      styleConfig: {},
      filterConfig: {},
      toggle: false,
      showContainer: true,
      sequences: [],
      userId: -1,
      sequence: {},
      sequenceId: "",
      presets: [],
      themes: [],
      objecttypes: [],
      modalIsOpen: false,
      modalType: "sequence",
      modalTextValue: "",
      domains: []
    };
    this.modal = {
        isOpen: false,
        type: "sequence",
        value: ""
    }
    this.focusNode = this.focusNode.bind(this)
    this.appRef = React.createRef();
    this.controlPanelRef = React.createRef();
    this.editorRef = React.createRef();
    this.filtersRef = React.createRef();
    this.styleRef = React.createRef();
    this.diagramRef = React.createRef();
    this.setEditorRef = this.setEditorRef.bind(this)
    this.setStyleRef = this.setStyleRef.bind(this)
    this.setFiltersRef = this.setFiltersRef.bind(this)
    this.getEditorRef = this.getEditorRef.bind(this)
    this.getStyleRef = this.getStyleRef.bind(this)
    this.getFiltersRef = this.getFiltersRef.bind(this)
    this.fetchSequence = this.fetchSequence.bind(this)
    this.fetchUserId = this.fetchUserId.bind(this)
    this.fetchPresets = this.fetchPresets.bind(this)
    this.fetchThemes = this.fetchThemes.bind(this)
    this.fetchObjectTypes = this.fetchObjectTypes.bind(this)
    this.buttonClick = this.buttonClick.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleStyleChange = this.handleStyleChange.bind(this)
    this.handleInputValue = this.handleInputValue.bind(this)
    this.handleSequenceSelectChange = this.handleSequenceSelectChange.bind(this)
    this.createPreset = this.createPreset.bind(this)
    this.createTheme = this.createTheme.bind(this)
    this.downloadCode = this.downloadCode.bind(this)
    this.saveClick = this.saveClick.bind(this)
    this.editName = this.editName.bind(this)
    this.getToken = this.getToken.bind(this)
    this.toggleObjectTypes = this.toggleObjectTypes.bind(this)
  }

  async getIdToken(){

      const claims = await this.auth0.getIdTokenClaims();
      const id_token = claims.__raw;
      this.id_token = id_token;
      return id_token
  }

  async getToken(){
      return await this.auth0.getAccessTokenSilently().then((token)=>{
          this.token = token;
          return this.token
      })

  }

  async editName(data, sequence_id){
      await this.api.patch(data, `sequence/${sequence_id}`).then(
          ret => {
              console.log(ret)
              this.fetchSequence();
          }

      )
  }

  async downloadCode(type){
      type = type.toLowerCase()
      let data = {
          type: "code",
          sequence_id: this.state.sequence.id,
          data_type: type
      }
      await this.api.download(data).then(
          ret => {
              console.log(ret)
              this.setState({
                  downloadLink: ret.url
              }, ()=>{
                  var dl = document.createElement('a');
                  dl.setAttribute('href', this.state.downloadLink);
                  dl.click();
              })
          }
      )

  }


  async createPreset(data, route){
      await this.api.post(data, route).then(
          ret => {
              console.log(ret)
          }
      )
  }

  async createTheme(data, route){
      await this.api.post(data, route).then(
          ret => {
              console.log(ret)
          }
      )
  }

  focusNode(lineNumber){

      this.setState({
          focusNodeInput: lineNumber
      }, ()=> {
          // alert("app: " + this.state.focusNodeInput.toString())
      })

  }


  handleSequenceSelectChange(evt){
      let sequence = _.filter(this.state.sequences, (seq) => {
          return seq.id === parseInt(evt.target.value)
      })[0]

      this.setState({
          sequence: sequence,
          sequenceId: sequence.id,
          inputValue: sequence.code.json
      }, ()=>{
          setTimeout(()=>{
              this.buttonClick();
          }, 500)
      })
  }


  handleInputValue = inputValue => {
      this.setState({
          inputValue: inputValue.src,
          outputValue: inputValue.expanded_src,
          domains: inputValue.domains
      })
  }

  handleStyleChange = styleConfig => {
      this.setState({
          styleConfig: styleConfig
      })
  }

  handleFilterChange = filterConfig => {
      this.setState({
          filterConfig: filterConfig
      })
  }


  async componentDidMount(){
      let token = await this.getToken()
      let id_token = await this.getIdToken()
      this.api = new Api(id_token)

      this.fetchUserId();
      this.fetchSequence();
      this.fetchPresets();
      this.fetchThemes();
      this.fetchObjectTypes();
  }

  async fetchSequence(seqId=null){
      await this.api.get("sequence").then(
          ret => {
              if (ret){
                  let sequences = ret.objects;
                  let sequence = sequences.length > 0 ? sequences[0] : {};
                  let sequenceId = Object.keys(sequence).indexOf("id") !== -1 ? sequence.id : ""
                  if (seqId !== null){
                      sequence = sequences.length > 0 ? _.filter(sequences, (seq)=>{
                          return seq.id == seqId
                      })[0] : {};
                      sequenceId = Object.keys(sequence).indexOf("id") !== -1 ? sequence.id : ""
                  }

                  let inputValue = sequences.length > 0 ? sequence.code.json : [];
                  this.setState({
                      sequences: sequences,
                      sequence: sequence,
                      sequenceId: sequenceId,
                      inputValue: inputValue
                  }, ()=> {
                      console.log("sequence!:")
                      console.log(this.state.sequence)
                      console.log(this.state.inputValue)
                      setTimeout(()=>{
                          this.buttonClick();
                      }, 500)
                  })
              }
          }
      )

  }

  async fetchPresets(){

      await this.api.get("preset").then(
          ret => {
              if (ret){
                  let presets = ret.objects;
                  this.setState({
                      presets: presets
                  }, ()=> {
                      setTimeout(()=>{
                          this.buttonClick();
                      }, 500)
                  })
              }
          }
      )

  }

  async fetchUserId(){

      await this.api.find("user", `?email=${this.props.auth0.user.email}`).then(
          ret => {
              if (ret){
                  let users = ret.objects;
                  this.setState({
                      userId: users[0].id
                  }, ()=> {
                      setTimeout(()=>{
                          this.buttonClick();
                      }, 500)
                  })
              }
          }
      )

  }

  async fetchThemes(){

      await this.api.get("theme").then(
          ret => {
              if (ret){
                  let themes = ret.objects;

                  this.setState({
                      themes: themes
                  }, ()=> {

                      setTimeout(()=>{
                          this.buttonClick();
                      }, 500)
                  })
              }
          }
      )

  }

  async fetchObjectTypes(){

      await this.api.get("objecttype").then(
          ret => {
              if (ret){
                  let objecttypes = ret.objects;

                  this.setState({
                      objecttypes: objecttypes
                  }, ()=> {

                      setTimeout(()=>{
                          this.buttonClick();
                      }, 500)
                  })
              }
          }
      )

  }

  async saveClick(){
      let styleConfigInitial = _.omit(this.state.styleConfig, ["ObjectTypes", "sequence"]);
      let styleConfig = {}
      let renameFields = {
          background: "background_color",
          connectionType: "connection_type",
          animated: "animated_connections",
          arrowhead: "arrowhead_type",
          labelBgPaddingX: "label_background_padding_x",
          labelBgPaddingY: "label_background_padding_y",
          labelBgBorderRadius: "label_background_border_radius",
          horizPadding: "horizontal_padding",
          vertPadding: "vertical_padding",
          labelBgStyle: "label_background_style",
          labelStyle: "label_style"
      }
      _.each(styleConfigInitial, function(value, key) {
                key = renameFields[key] || key;
                styleConfig[key] = value;
            });
      let filterConfig = _.omit(this.state.filterConfig, "sequence");
      let code = this.state.inputValue;
      let data = {};
      data["code"] = this.state.sequence.code;
      data["code"]["json"] = code
      data["code"]["plaintext"] = code
      data["filters_json"] = filterConfig
      data["style_json"] = styleConfig
      data["object_types_json"] = this.styleRef.current.fromMap(this.styleRef.current.state.ObjectTypes)

      console.log("save data:")
      console.log(data)

      await this.api.patch(data, `sequence/${this.state.sequence.id}`).then(
          ret => {
              console.log(ret)
          }
      )
  }

  buttonClick(){
      this.setState({
          inputValue: this.editorRef.current.state.src,
          outputValue: this.editorRef.current.state.expanded_src
      },()=>{
          console.log("SHOW REFS:")
          console.log("filtersRef:", this.filtersRef)
          console.log("styleRef:", this.styleRef)
          console.log("editorRef:", this.editorRef)

          this.filtersRef.current.buttonClick();
          this.styleRef.current.buttonClick();
          this.editorRef.current.buttonClick();
      })

  }

  getEditorRef(){
      return this.editorRef;
  }

  getStyleRef(){
      return this.styleRef;
  }

  getFiltersRef(){
      return this.filtersRef;
  }


  setEditorRef(ref){
      this.editorRef = ref;
  }

  setStyleRef(ref){
      this.styleRef = ref;
  }

  setFiltersRef(ref){
      this.filtersRef = ref;
  }

  renderObjectTypes(){
      let response = null
      if (this.styleRef.current !== null){
          response = this.styleRef.current.renderObjectTypesSimple();
      }
      return response
  }

  toggleObjectTypes(){
      this.setState({
          showObjectTypes: !this.state.showObjectTypes
      })
  }

  onResize = (event, {element, size, handle}) => {
    this.setState({width: size.width, height: size.height});
  };


  render() {
      console.log("render modal:")
      console.log(this.modal)
      var reattachButton = (<button style={{position: "absolute", top: 0, left: 0, zIndex: 100000}} type='button'>Close!</button>)
    var detachButton = (<button style={{position: "absolute", top: 0, left: 0, zIndex: 100000}} type='button'>Detach!</button>)
    const childResponse = (err, res) => {

    if (err) {
      console.log(res, 'err')
      this.setState(res)
    }
    console.log(res, 'res')
    this.setState(res)
  }
    return (
        <div>
        {this.props.simple ? (
            <ControlPanel
                focusNode={this.focusNode}
                fullScreen={true}
                api={this.api}
                userId={this.state.userId}
                fetchSequence={this.fetchSequence}
                getEditorRef={this.getEditorRef}
                getStyleRef={this.getStyleRef}
                getFiltersRef={this.getFiltersRef}
                setEditorRef={this.setEditorRef}
                setStyleRef={this.setStyleRef}
                setFiltersRef={this.setFiltersRef}
                editorRef={this.editorRef}
                filtersRef={this.filtersRef}
                styleRef={this.styleRef}
                sequence={this.state.sequence}
                sequenceId={this.state.sequenceId}
                sequences={this.state.sequences}
                handleFilterChange={this.handleFilterChange}
                handleStyleChange={this.handleStyleChange}
                handleInputValue={this.handleInputValue}
                handleSequenceSelectChange={this.handleSequenceSelectChange}
                presets={this.state.presets}
                themes={this.state.themes}
                objecttypes={this.state.objecttypes}
                createPreset={this.createPreset}
                createTheme={this.createTheme}
                data={this.state.inputValue}
                download={this.downloadCode}
                buttonClick={this.buttonClick}
                inputValue={this.state.inputValue}
                outputValue={this.state.outputValue}
                saveClick={this.saveClick}
                editName={this.editName}
            />
        ) : (
            <div ref={this.appRef} id="App" className="App">

              <div id="logoContainer">

              <div id="logo">
              </div>
              <WindowOpener width={"1200"} height={"800"} url='#/controlpanel' bridge={childResponse}>
              <button style={{
                  position: "absolute",
                  top: "10px",
                  right: "100px",
                  width: "100px"
              }} onClick={()=>{}}>Expand</button>



            </WindowOpener>
            <button style={{
                position: "absolute",
                top: "10px",
                right: "200px",
                width: "100px"
            }} onClick={this.toggleObjectTypes}>Object Types</button>

              </div>

              <SideBar pageWrapId={"controlPanel"} outerContainerId={"App"} />


              <ControlPanel
                  focusNode={this.focusNode}
                  api={this.api}
                  userId={this.state.userId}
                  fetchSequence={this.fetchSequence}
                  getEditorRef={this.getEditorRef}
                  getStyleRef={this.getStyleRef}
                  getFiltersRef={this.getFiltersRef}
                  setEditorRef={this.setEditorRef}
                  setStyleRef={this.setStyleRef}
                  setFiltersRef={this.setFiltersRef}
                  editorRef={this.editorRef}
                  filtersRef={this.filtersRef}
                  styleRef={this.styleRef}
                  sequence={this.state.sequence}
                  sequenceId={this.state.sequenceId}
                  sequences={this.state.sequences}
                  handleFilterChange={this.handleFilterChange}
                  handleStyleChange={this.handleStyleChange}
                  handleInputValue={this.handleInputValue}
                  handleSequenceSelectChange={this.handleSequenceSelectChange}
                  presets={this.state.presets}
                  themes={this.state.themes}
                  objecttypes={this.state.objecttypes}
                  createPreset={this.createPreset}
                  createTheme={this.createTheme}
                  data={this.state.inputValue}
                  download={this.downloadCode}
                  buttonClick={this.buttonClick}
                  inputValue={this.state.inputValue}
                  outputValue={this.state.outputValue}
                  saveClick={this.saveClick}
                  editName={this.editName}
              />
              {!this.props.simple &&(
                  <div className="App">
                  {this.state.showObjectTypes && (
                      <ResizableBox resizeHandles={['sw']} className="resize-box" height={200} width={200}>

                      <div style={{

                          borderBottom: "gray 1px solid",
                          alignItems: "center",
                          overflow: "scroll",
                          zIndex: 100,
                          height: "100%",
                          width: "100%",
                          backgroundColor: "black"
                      }} className="StyleEditorRow">
                          {this.renderObjectTypes()}
                          </div>

                          </ResizableBox>
                  )}


                  <MyLayoutFlow focusNodeInput={this.state.focusNodeInput} ref={this.diagramRef} domains={this.state.domains} sequence={this.state.outputValue} styleConfig={this.state.styleConfig} filterConfig={this.state.filterConfig} />
                  </div>

              )}


            </div>

        )}
        </div>
    );
  }
}

class SimpleApp extends Component {
    constructor(props) {
      super(props);
      this.state = {


      }
      this.appRef = React.createRef();


    }
    doit(){
        // prompt("test", JSON.stringify(this.appRef.state, null, 4))
        window.opener.onSuccess(this.appRef.current.state)
    }
    componentDidMount() {
      this.interval = setInterval(() => this.doit(), 1000);
    }
    componentWillUnmount() {
      clearInterval(this.interval);
    }
    render(){
        return (<App ref={this.appRef} auth0={this.props.auth0} simple={true} />);
    }
}



const AppRouter = () => {
  return (
    <HashRouter basename={process.env.PUBLIC_URL}>
      <Switch>
        <Route
            path='/controlpanel'
            exact component={withAuth0(SimpleApp)}
            />
        <Route component={withAuth0(App)} />
      </Switch>
    </HashRouter>
  )
}

export default AppRouter;
