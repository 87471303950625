import React from "react";

const USERNAME = "admin"
const API_KEY = "test"
const BASE_URL = "https://autoflow-pro.herokuapp.com/api/v1"
// const BASE_URL = "http://localhost:8000/api/v1"
const BASE_DOWNLOAD_URL = "https://autoflow-pro.herokuapp.com/download"
const CREDENTIALS = `?username=${USERNAME}&api_key=${API_KEY}`
// const CREDENTIALS2 = `&username=${USERNAME}&api_key=${API_KEY}`


class Api extends React.PureComponent {
    constructor(token){
        super();

        this.token = token


    }

    async get(model) {
        try {
            let response = await fetch(
                `${BASE_URL}/${model}/${CREDENTIALS}`,
                {
                    headers: {
                        'Authorization': `Bearer ${this.token}`,
                        'Accept': "application/json",
                        "Content-Type": "application/json"
                    }
                }
            )
            if (!response.ok) {
                throw Error(response.statusText);
            }
            let data = await response.json()

            return data
        } catch (error) {
            console.log(error);
        }

    }

    async find(model, params) {
        try {
            let response = await fetch(
                `${BASE_URL}/${model}/${params}`,
                {
                    headers: {
                        'Authorization': `Bearer ${this.token}`,
                        'Accept': "application/json",
                        "Content-Type": "application/json"
                    }
                }
            )
            if (!response.ok) {
                throw Error(response.statusText);
            }
            let data = await response.json()

            return data
        } catch (error) {
            console.log(error);
        }

    }
    async patch(data, model){
        console.log("patch data: ", data)
        console.log("patch model: ", model)
        try {
            let response = await fetch(
                `${BASE_URL}/${model}/${CREDENTIALS}`,
                {
                    method: "PATCH",
                    headers: {
                        'Authorization': `Bearer ${this.token}`,
                        'Accept': "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data)
                }
            )
            if (!response.ok)  {
                throw Error(response.statusText);
            }
            let resp_data = await response.json()

            return resp_data
        } catch (error) {
            console.log(error);
        }

    }

    async post(data, model){
        console.log("post data: ", data)
        console.log("post model: ", model)
        try {
            let response = await fetch(
                `${BASE_URL}/${model}/${CREDENTIALS}`,
                {
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${this.token}`,
                        'Accept': "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data)
                }
            )
            if (!response.ok)  {
                throw Error(response.statusText);
            }
            let resp_data = await response.json()

            return resp_data
        } catch (error) {
            console.log(error);
        }

    }
    async download(data){

        try {
            let response = await fetch(
                `${BASE_DOWNLOAD_URL}/${CREDENTIALS}`,
                {
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${this.token}`,
                        'Accept': "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data)
                }
            )
            if (!response.ok)  {
                throw Error(response.statusText);
            }
            let resp_data = await response.json()

            return resp_data
        } catch (error) {
            console.log(error);
        }

    }
}

export default Api
