import React from 'react';
import { render } from 'react-dom';
import MonacoEditor from 'react-monaco-editor';
import YAML from 'json-to-pretty-yaml';
import { expand } from '../utils/TransformJSON';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import Fab from "@material-ui/core/Fab";
import Analysis from "./Analysis";
// import {writeJsonFile} from 'write-json-file';
const yaml = require('js-yaml');

// const writeJsonFile = require('write-json-file');
// const writeJsonFile = require('write-json-file');

function jsonEscape(str)  {
    return str.replace(/\n/g, "\\\\n").replace(/\r/g, "\\\\r").replace(/\t/g, "\\\\t");
}


class Monaco extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '// type your code...',
      src: [],
      expanded_src: [],
      language: "json",
      mode: "JSON",
      selectValue: "Editor",
      showCodeButtons: true,
      selectedText: "",
      showAnalysis: false,
      analyzeText: "ANALYZE",
      analyzeColor: "primary",
      domains: []
    }
    this.editorDidMount = this.editorDidMount.bind(this)
    // this.editorWillMount = this.editorWillMount.bind(this)
    this.onChange = this.onChange.bind(this)
    this.buttonClick = this.buttonClick.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
    this.saveButtonClick = this.saveButtonClick.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.showFile = this.showFile.bind(this)
    this.hideCodeButtons = this.hideCodeButtons.bind(this)
    this.showCodeButtons = this.showCodeButtons.bind(this)
    this.analyze = this.analyze.bind(this)
    this.onDidType = this.onDidType.bind(this)

  }
  onDidType(text){
      // alert(text)
     if (text === '\n') {
         // alert("new line")
         this.buttonClick()
     }
  }
  hideCodeButtons(){
      this.setState({
          showCodeButtons: false
      })
  }
  showCodeButtons(){
      this.setState({
          showCodeButtons: true
      })
  }
  handleClick(click_type){
      this.props.download(click_type)
  }
  componentWillReceiveProps(nextProps) {
      let code = "";
      let language = "";
      let newCode = "";

      console.log("componentWillReceiveProps")
  // You don't have to do this check first, but it can help prevent an unneeded render

      // if (JSON.stringify(nextProps.data, null, 4) !== this.state.startTime) {
          if (this.state.mode == "JSON"){
              code = JSON.stringify(nextProps.data, null, 4)
              language = "json"
              newCode = JSON.stringify(nextProps.data, null, 4)
          }
          if (this.state.mode == "YAML"){
              code = YAML.stringify(nextProps.data)
              language = "yaml"
              newCode = YAML.stringify(nextProps.data)
          }
          if (this.state.mode == "EASY"){
              try{
                 code = YAML.stringify(nextProps.data)
                 newCode = YAML.stringify(nextProps.data)
              }
              catch{
                  try{
                      code = JSON.stringify(nextProps.data, null, 4)
                      newCode = JSON.stringify(nextProps.data, null, 4)
                  }
                  catch{

                  }
              }

              code = code.replace(/"/g, "")
              code = code.replace(/\\n/g, "\n")
              // code = nextProps.data
              language = "EASY"
              newCode = nextProps.data
          }

          if (newCode !== this.state.code){
              this.setState({
                  selectValue: nextProps.selectValue,
                  code: code,
                  src: nextProps.data,
                  expanded_src: expand(nextProps.data)
              },()=>{
                  console.log("newCode:")
                  console.log(newCode)
                  console.log("Editor state:")
                  console.log(this.state);
                  // this.props.handleInputValue(
                  //     {
                  //         src: this.state.src,
                  //         expanded_src: this.state.expanded_src
                  //     }
                  // );

              });
          }


      // }
    }

    analyze(){
        let domains = [];
        try{

            if (typeof(this.state.src.domains) == "object"){
                domains = this.state.src.domains
            }
        }
        catch(err){
            console.log(err)
        }
        this.setState({
            domains: domains,
            selectedText: this.state.editor.getModel().getValueInRange(this.state.editor.getSelection()),
            showAnalysis: !this.state.showAnalysis,
            analyzeText: this.state.analyzeText == "END" ? "ANALYZE" : "END",
            analyzeColor: this.state.analyzeColor == "primary" ? "secondary" : "primary"

        })
    }
  componentDidMount(){
      let code = "";
      let language = "";
      if (this.props.mode == "JSON"){
          code = JSON.stringify(this.props.data, null, 4)
          language = "json"
      }
      if (this.props.mode == "YAML"){
          code = YAML.stringify(this.props.data)
          language = "yaml"
      }
      if (this.props.mode == "EASY"){
          // code = this.props.data
          try{
             code = YAML.stringify(this.props.data)
             // newCode = YAML.stringify(nextProps.data)
          }
          catch{
              try{
                  code = JSON.stringify(this.props.data, null, 4)
                  // newCode = JSON.stringify(nextProps.data, null, 4)
              }
              catch{

              }
          }
          language = "EASY"

      }
      this.setState({
          selectValue: this.props.selectValue,
          code: code,
          language: language,
          src: this.props.data,
          mode: this.props.mode,
          expanded_src: expand(this.props.data)
      }, ()=>{
          // console.log("expanded_src:", this.state.expanded_src);
          this.props.handleInputValue(
              {
                  src: this.state.src,
                  expanded_src: this.state.expanded_src
              }
          );
      });
  }
  editorWillMount(monaco){
      monaco.languages.register({ id: 'EASY' });

      monaco.languages.setMonarchTokensProvider('EASY', {
      	tokenizer: {
      		root: [
                ["domains:", "custom-error"],
                ["commands:", "custom-date"],
                [/[a-zA-Z 0-9:]+\:/gi, "custom-notice"],
                [/\- [a-zA-Z 0-9:]+/, "custom-info"],
                [/\-> \[.*/, "custom-tag"],
                [/\-> [a-zA-Z 0-9:]+/, "custom-type"],
      			[/\[error.*/, "custom-error"],
      			[/\[notice.*/, "custom-notice"],
      			[/\[info.*/, "custom-info"],
      			[/\[[a-zA-Z 0-9:]+\]/, "custom-date"],
      		]
      	}
      });
      monaco.editor.defineTheme('myCoolTheme', {
      	base: 'vs-dark',
      	inherit: true,
      	rules: [
      		{ token: 'custom-info', foreground: '808080' },
      		{ token: 'custom-error', foreground: 'ff6e61', fontStyle: 'bold' },
      		{ token: 'custom-notice', foreground: 'FFA500' },
      		{ token: 'custom-date', foreground: '60ff54' },
            { token: 'custom-type', foreground: 'dda0dd' },
            { token: 'custom-tag', foreground: 'eb6e34' },
      	]
      });


  }
  editorDidMount(editor, monaco) {
    // console.log('editorDidMount', editor);
    // this.editor = editor

    // monaco.languages.register({ id: 'EASY' });
    //
    // monaco.languages.setMonarchTokensProvider('EASY', {
    //   tokenizer: {
    //       root: [
    //           ["domains:", "custom-error"],
    //           ["commands:", "custom-date"],
    //           [/[a-zA-Z 0-9:]+\:/gi, "custom-notice"],
    //           [/\- .*/, "custom-info"],
    //           [/\-> \[.*/, "custom-tag"],
    //           [/\-> [a-zA-Z 0-9:]+/, "custom-type"],
    //           [/\[error.*/, "custom-error"],
    //           [/\[notice.*/, "custom-notice"],
    //           [/\[info.*/, "custom-info"],
    //           [/\[[a-zA-Z 0-9:]+\]/, "custom-date"],
    //       ]
    //   }
    // });
    // monaco.editor.defineTheme('myCoolTheme', {
    //   base: 'vs',
    //   inherit: false,
    //   rules: [
    //       { token: 'custom-info', foreground: '808080' },
    //       { token: 'custom-error', foreground: 'ff0000', fontStyle: 'bold' },
    //       { token: 'custom-notice', foreground: 'FFA500' },
    //       { token: 'custom-date', foreground: '008800' },
    //       { token: 'custom-type', foreground: 'dda0dd' },
    //       { token: 'custom-tag', foreground: 'eb6e34' },
    //   ]
    // });
    this.setState({
        editor: editor
    })
    editor.focus();

    function highlight(){
        let model = editor.getModel()
        let line = model.getLineContent(editor.getPosition().lineNumber - 1)
        let pos = editor.getPosition();
        var id = { major: 1, minor: 1 };
        let range = new monaco.Range(pos.lineNumber - 1, pos.column, pos.lineNumber, pos.column);
        let text = "[" + line + "]"
        var op = {identifier: id, range: range, text: text, forceMoveMarkers: true};
        editor.executeEdits("my-source", [op]);
    }

    function highlight2(){
        let model = editor.getModel()
        let line = model.getLineContent(editor.getPosition().lineNumber)
        let pos = editor.getPosition();
        var id = { major: 1, minor: 1 };
        let range = new monaco.Range(pos.lineNumber, pos.column, pos.lineNumber + 1, pos.column);
        let text = "[" + line + "]"
        var op = {identifier: id, range: range, text: text, forceMoveMarkers: true};
        editor.executeEdits("my-source", [op]);
    }

    editor.onDidType(text => {



        if (this.state.mode == "EASY"){

            if (text === '\n') {

                // highlight()
                this.buttonClick()
            }
        }

    })
    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_S, () => {
        if (this.state.mode == "EASY"){
            // highlight2()
            this.buttonClick()
        }
    })

    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_G, () => {
        if (this.state.mode == "EASY"){
            // highlight2()
            // let lineNumber = editor.getPosition().lineNumber;
            let selection = editor.getSelection();
            let lineNumber = selection.startLineNumber.toString() + ", " + selection.endLineNumber.toString()
            this.props.focusNode(lineNumber)
            // alert("monaco")
            // alert(editor.getPosition().lineNumber)
            // let finput = document.getElementById("focusNodeInput")
            // finput.setAttribute('value', editor.getPosition().lineNumber)
            // let fbutton = document.getElementById("focusNodeButton")
            // fbutton.click()
        }
    })


    // editor.addAction({
    // 	// An unique identifier of the contributed action.
    // 	id: 'my-unique-id',
    //
    // 	// A label of the action that will be presented to the user.
    // 	label: 'My Label!!!',
    //
    // 	// An optional array of keybindings for the action.
    // 	keybindings: [
    // 		monaco.KeyCode.Ctrl
    // 	],
    //
    // 	// A precondition for this action.
    // 	precondition: null,
    //
    // 	// A rule to evaluate on top of the precondition in order to dispatch the keybindings.
    // 	keybindingContext: null,
    //
    // 	contextMenuGroupId: 'navigation',
    //
    // 	contextMenuOrder: 1.5,
    //
    // 	// Method that will be executed when the action is triggered.
    // 	// @param editor The editor instance is passed in as a convinience
    // 	run: function(ed) {
    //         alert("hi")
    //         if (this.state.mode == "EASY"){
    //             this.buttonClick()
    //         }
    // 		return null;
    // 	}
    // });
    // editor.addCommand(monaco.KeyCode.Tab, function() {
    // // services available in `ctx`
    //         if (this.state.mode == "EASY"){
    //             this.buttonClick()
    //         }
    //
    // })



  }
  buttonClick(){
      // let src = [];
      console.log("buttonClick Editor")

      try {
          // if (this.state.mode == "JSON"){
          //     src = JSON.parse(newValue)
          // }
          // if (this.state.mode == "YAML"){
          //     src = yaml.load(newValue)
          let expanded = expand(this.state.src)
          console.log("expanded:")
          console.log(expanded)

          let domains = [];

          if (this.state.mode == "EASY"){
              try{
                  domains = expanded[0].domains
              }
              catch(err){
                  console.log(err)
              }
          }
          else {
              try{
                 if (Object.keys(this.state.src).indexOf("domains") !== -1){
                     domains = this.state.src.domains

                 }
              }
              catch(err){
                  console.log(err)
              }
          }


          // }

          console.log("domains:", domains)

          this.setState({
              // code: newValue,
              // src: src,
              expanded_src: expanded,
              domains: domains
          }, ()=>{
              this.props.handleInputValue(
                  {
                      src: this.state.src,
                      expanded_src: this.state.expanded_src,
                      domains: this.state.domains
                  }
              );
              // this.buttonClick = this.buttonClick.bind(this);
          })
      }
      catch(err){
          console.log(err)
      }
  }
  // saveButtonClick = async () => {
  //   await writeJsonFile('foo.json', this.state.src);
  //   }
  saveButtonClick(){
      // var test = async function () {
      //
      //        await writeJsonFile('foo.json', {foo: true});
      //    }
      //    test();
  }

  onChange(newValue, e) {
    // console.log('onChange', newValue, e);
    // console.log('this.state.mode:', this.state.mode);

    let src = [];
    try {
        if (this.state.mode == "JSON"){
            src = JSON.parse(newValue)
        }
        if (this.state.mode == "YAML"){
            src = yaml.load(newValue)
        }
        if (this.state.mode == "EASY"){
            src = newValue
        }
        this.setState({
            code: newValue,
            src: src,
            expanded_src: expand(src)
        }, ()=>{
            // try{
            //     this.buttonClick();
            // }
            // catch(err){
            //     console.log(err)
            // }

            // this.props.handleInputValue(this.state.expanded_src);
            // this.buttonClick = this.buttonClick.bind(this);
        })
    }
    catch(err){
        console.log(err)
    }

  }
  handleSelectChange(evt){
      let code = "";
      let language = "";
      let mode = evt.target.value;
      if (mode == "JSON"){
          code = JSON.stringify(this.state.src, null, 4)
          language = "json"
      }
      if (mode == "YAML"){
          code = YAML.stringify(this.state.src)
          language = "yaml"
      }
      if (mode == "EASY"){
          if (this.state.mode == "JSON"){
              code = JSON.stringify(this.state.src, null, 4)
          }
          if (this.state.mode == "YAML"){
              code = YAML.stringify(this.state.src)
          }
          code = code.replace(/"/g, "")
          code = code.replace(/\\n/g, "\n")
          // code = this.state.src
          // code = jsonEscape(code)
          // alert(typeof(code))
          language = "EASY"
      }
      this.setState({
          code: code,
          language: language,
          // src: this.props.data,
          mode: mode,
          // expanded_src: expand(this.props.data)
      }, ()=>{

          console.log("handleSelectChange:")
          console.log("code:", this.state.code);
          console.log("language:", this.state.language);
          console.log("mode:", this.state.mode);
          // console.log("expanded_src:", this.state.expanded_src);
          // this.props.handleInputValue(
          //     {
          //         src: this.state.src,
          //         expanded_src: this.state.expanded_src
          //     }
          // );
      });

  }
  isJson(text){
      try{
          JSON.parse(text)
          return true
      }
      catch(err){
          console.log(err)
          return false
      }
  }
  showFile = async (e) => {
    e.preventDefault()
    const reader = new FileReader()
    reader.onload = async (e) => {
      const text = (e.target.result)
      // console.log(text)
      const isJson = this.isJson(text);
      console.log("text:", text)
      console.log("isJson:", isJson)
      let src = isJson ? JSON.parse(text) : yaml.load(text)
      // let code = isJson ? JSON.stringify(JSON.parse(text), null, 4) : YAML.stringify(YAML.load(text))
      // let language = isJson ? "json" : "yaml";
      let code = ""
      if (this.state.mode == "JSON"){
          code = JSON.stringify(src, null, 4)
      }
      if (this.state.mode == "YAML"){
          code = YAML.stringify(src)
      }

      this.setState({
          code: code,
          // language: language,
          src: src,
          // mode: this.props.mode,
          expanded_src: expand(src)
      }, ()=>{
          // console.log("expanded_src:", this.state.expanded_src);
          this.props.handleInputValue(
              {
                  src: this.state.src,
                  expanded_src: this.state.expanded_src
              }
          );
      });
    };
    reader.readAsText(e.target.files[0])
  }
  render() {
    const code = this.state.code;
    const options = {
      selectOnLineNumbers: true,
      tabSize: 2
    };
    const { classes } = this.props;
    const controlPanel = document.getElementById("controlPanel")
    let editorWidth = 740;
    if (controlPanel !== null){
        editorWidth = controlPanel.clientWidth * .9
    }
    return (
        <div className={"codeContainer"}>

          <MonacoEditor
            width={editorWidth}
            height="90%"
            language={this.state.language}
            theme="myCoolTheme"
            value={code}
            options={options}
            onChange={this.onChange}
            editorWillMount={this.editorWillMount}
            editorDidMount={this.editorDidMount}
          />

          {this.state.showAnalysis && (
              <div className="analysis">
              <Analysis initialText={this.state.selectedText} domains={this.state.domains} />
              </div>
          )}

          {this.state.showCodeButtons && (
              <div className={"codeButtons"}>
                <div style={{justifyContent: "center"}} className="StyleEditorRow">
                        <div style={{alignItems: "center"}} className="styleItem">
                            <ButtonGroup variant="contained" color="primary" aria-label="contained secondary button group">
                            <Button onClick={() => this.handleClick("JSON")}>
                                Download JSON
                            </Button>
                            <Button onClick={() => this.handleClick("YAML")}>
                                Download YAML
                            </Button>
                            </ButtonGroup>
                        </div>

                        <div style={{alignItems: "center"}} className="styleItem">

                            <FormControl variant="filled" className={classes.formControl}>
                              <InputLabel id="demo-simple-select-outlined-label">Data Type</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    value={this.state.mode}
                                    onChange={this.handleSelectChange}
                                    label="Mode"
                                  >
                                  <MenuItem value={"JSON"}>{"JSON"}</MenuItem>
                                  <MenuItem value={"YAML"}>{"YAML"}</MenuItem>
                                  <MenuItem value={"EASY"}>{"EASY"}</MenuItem>
                                  </Select>
                              </FormControl>

                        </div>
                        <div style={{alignItems: "center"}} className="styleItem">


                            <label style={{backgroundColor: "transparent"}} htmlFor="raised-button-file">
                              <Button onClick={() => this.analyze()} variant="contained" color={this.state.analyzeColor} className={classes.button}>
                                {this.state.analyzeText}
                              </Button>
                            </label>
                        </div>
                        <div style={{alignItems: "center"}} className="styleItem">

                            <input
                              className={classes.input}
                              id="raised-button-file"
                              type="file"
                              style={{ display: 'none' }}
                              onChange={(e) => this.showFile(e)}
                            />
                            <label style={{backgroundColor: "transparent"}} htmlFor="raised-button-file">
                              <Button variant="contained" color="primary" className={classes.button}>
                                Upload
                              </Button>
                            </label>
                        </div>

                    </div>
                </div>
          )}

    </div>
    );
  }
}

export default Monaco;
