import React, { useState, useCallback, useEffect } from 'react';
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  removeElements,
  isNode,
  Elements,
  XYPosition,
  MiniMap,
  Controls,
  Background,
  useStore,
  useZoomPanHelper,
  useStoreActions,

} from 'react-flow-renderer';
import localforage from 'localforage';

import CustomEdge from '../utils/CustomEdge';

import dagre from 'dagre';

import '../stylesheets/layouting.css';

import Diagram from "../utils/Helpers"

import ZoomHelper from "../utils/ZoomHelper"

// import ObjectTypes from './FlowHelper';

import _ from 'lodash'

localforage.config({
  name: 'autoflow',
  storeName: 'flows',
});

const flowKey = 'my-flow';

const uuid = require('react-uuid');

const position: XYPosition = { x: 0, y: 0 };
const edgeType = 'smoothstep';
var horizPadding = 200;
var vertPadding = 200;
const startX = 100;
const startY = 100;
const yInc = 50;
const autoLayout = false;

const edgeTypes = {
  custom: CustomEdge,
};

// class Diagram {
//     constructor(domains){
//         this.domains = domains
//         this.currentRow = 0
//         this.lastDomain = null
//         this.cols = []
//         this.colMap = {}
//         this.objects = []
//         this.positionedObjects = []
//         this.connections = []
//         this.rows = []
//         this.initMapFromDomains(domains)
//     }
//
//     getPositionedObjects(horizPadding, vertPadding){
//         let startX = 0;
//         this.positionedObjects = []
//         _.each(this.cols, (col, i) => {
//             let obj = {...col}
//             let nowX = startX + ( i  * horizPadding)
//             let colWidth = this.colMap[obj.name].width
//             this.colMap[obj.name].startX = nowX
//             obj.position = {
//                 x: nowX,
//                 y: 0
//             }
//             startX = nowX + (horizPadding * (colWidth - 1))
//             this.positionedObjects.push(obj)
//         })
//         _.each(this.rows, (rowObjs, i) => {
//             _.each(rowObjs, (rowObj, x) => {
//                 let obj = rowObj["obj"]
//                 let col = rowObj["col"]
//                 let colWidth = this.colMap[col].width
//                 let colPos = this.colMap[col].currentPos
//                 let colOrder = this.colMap[col].order
//                 let startX = this.colMap[col].startX
//                 obj.position = {
//                     x: startX + (horizPadding * colPos),
//                     y: (i + 1) * vertPadding
//                 }
//                 this.colMap[col].currentPos = colPos + 1
//                 if (this.colMap[col].currentPos == colWidth){
//                     this.colMap[col].currentPos = 0
//                 }
//                 this.positionedObjects.push(obj)
//
//             })
//         })
//
//
//     }
//
//     colExists(name){
//         let colNames = _.map(this.cols, (col) => {
//             return col.name
//         })
//         return colNames.indexOf(name) !== -1
//     }
//
//     addConnection(conn){
//         this.connections = [...this.connections, conn]
//     }
//
//     addCol(obj){
//         console.log("obj.name:", obj.name)
//         console.log("domains:", this.domains)
//
//         if (this.domains.indexOf(obj.name) !== -1){
//             this.colMap[obj.name] = {
//                 width: 0,
//                 currentPos: 0,
//                 order: this.cols.length
//             }
//             this.cols = [...this.cols, obj]
//             this.objects = [...this.objects, obj]
//         }
//     }
//
//     addObject(domain, obj, row){
//         // this.domainMap[domain].rows[row]
//         // console.log(this.domainMap)
//         // console.log(domain)
//         // console.log(row)
//
//         console.log("add object")
//         console.log("domain:", domain)
//         console.log("row:", row)
//         // console.log("obj:", obj)
//
//
//         if (Object.keys(this.domainMap).indexOf(domain) == -1){
//             if (this.lastDomain !== null) {
//                 domain = this.lastDomain
//             }
//         }
//         else {
//             this.lastDomain = domain
//         }
//
//
//         let rows = this.domainMap[domain].rows;
//
//         row = row.toString()
//
//         if (Object.keys(rows).indexOf(row) == -1){
//             this.domainMap[domain].rows[row] = []
//         }
//         this.domainMap[domain].rows[row].push(obj)
//
//
//         console.log(typeof(this.rows[row]))
//         if (typeof(this.rows[row]) !== "object"){
//             this.rows[row] = []
//         }
//
//         this.rows[row].push(
//             {
//                 "col": domain,
//                 "obj": obj
//             }
//         )
//
//         let colRow = this.domainMap[domain].rows[row]
//         if (colRow.length > this.colMap[domain].width){
//             this.colMap[domain].width = colRow.length
//         }
//         this.objects = [...this.objects, obj]
//
//     }
//
//     initMapFromDomains(domains) {
//         let domainMap = {}
//         _.each(domains, (domain) => {
//             domainMap[domain] = {
//                 rows: {}
//             }
//         })
//         this.domainMap = domainMap
//     }
//
// }


function get_name(name, names){


    if (names.indexOf(name) == -1){
        return name;
    }
    else {
        return get_name(`${name}*`, names)
    }
}


const onNodeDragStop = (event, node) => console.log('drag stop', node);
const onElementClick = (event, element) => console.log('click', element);

const createObject = (params) => {
    return {
        id: params.id,
        lineId: params.lineId,
        type: params.type,
        data: {
            label: params.name
        },
        sourcePosition: params.sourcePosition,
        targetPosition: params.targetPosition,
        position: params.position,
        dependent_on: params.dependent_on,
        timestamp: new Date().getTime(),
        style: params.style,
        labelStyle: params.labelStyle,
        ...params
    }
}

function createConnection(params){
    return {
        id: params.id,
        source: params.source,
        target: params.target,
        type: params.type,
        animated: params.animated,
        label: params.label,
        ...params
    }
}

function idFromName(name, objects){
    // console.log("idFromName:")
    // console.log("name:", name)
    // console.log("objects:", objects)
    let results = [];
    for (var i = 0; i < objects.length; i++){
        let obj = objects[i];
        if (name == obj.data.label){
            results.push( obj);
        }
    }
    if (results.length > 0){
        results.sort(function(a, b){
            return b.timestamp - a.timestamp
            })
            return results[0].id
    }
    return null
}

function objFromName(name, objects){
    // console.log("objFromName:")
    // console.log("name:", name)
    // console.log("objects:", objects)
    name = name.trim()
    let results = [];
    for (var i = 0; i < objects.length; i++){
        let obj = objects[i];
        // console.log("comparing:", obj.name, name)
        if (name == obj.name){
            // console.log("match:", obj.name)
            results.push( obj);
        }
    }
    if (results.length > 0){
        results.sort(function(a, b){
            return b.timestamp - a.timestamp
            })
            // console.log("results:", results)
            return results[0]
    }
    return null
}

function positionFromName(name, objects){
    let results = [];
    for (var i = 0; i < objects.length; i++){
        let obj = objects[i];
        if (name == obj.data.label){
            results.push( obj);
        }
    }
    if (results.length > 0){
        results.sort(function(a, b){
            return b.timestamp - a.timestamp
            })
            return results[0].position
    }
    return null
}

const getObjectsConnectionsFromStatement = (
    stmt,
    objects,
    cols,
    row,
    seen,
    timestep,
    styleConfig,
    domains,
    dependent_on=null,
    dependent_subject=null
) => {

    horizPadding = parseFloat(styleConfig.horizPadding);
    vertPadding = parseFloat(styleConfig.vertPadding);

    let ObjectTypes = styleConfig.ObjectTypes;

    // console.log("horizPadding: ", horizPadding)
    // console.log("vertPadding: ", vertPadding)

    let statement = stmt.command;



    let statement_options = stmt.options;

    let obj = [];
    let conn = [];
    let newSeen = [];
    let whats = [];
    let lookup = {};
    let verbId = null;
    let source = null;
    if (statement.subject !== null){

        if (Object.keys(cols).indexOf(statement.subject) == -1){
            let in_domains = domains.indexOf(statement.subject) !== -1
            // console.log("in_domains:", statement.subject, in_domains)
            if (in_domains){
                cols[statement.subject] = {
                    col: Object.keys(cols).length,
                    bounds: [
                        horizPadding * Object.keys(cols).length,
                        (horizPadding * Object.keys(cols).length) + horizPadding
                    ]
                }
                obj.push(
                    createObject(
                        {
                            id: uuid(),
                            lineId: statement.lineId,
                            type: "input",
                            name: statement.subject,
                            sourcePosition: null,
                            targetPosition: null,
                            dependent_on: null,
                            position: { x: cols[statement.subject].bounds[0], y: vertPadding },
                            style: ObjectTypes["Terminator"].style,
                            labelStyle: ObjectTypes["Terminator"].labelStyle,
                            className: "nodeBack",
                        }

                    )
                );
            }


        }


    }
    if (statement.verb !== null && statement.what !== null){
        let objId = uuid();
        // alert(JSON.stringify(statement))
        let lineId = statement.lineId;
        verbId = objId;
        let objName = statement.what;
        let objType = null;
        let objLineId = statement.lineId;
        if (statement.break){
            objType = "output";
        }

        let names = _.map([...objects, ...obj], (o) => {
            return o.data.label;
            })

        // objName = get_name(objName, names)
        let xPos = 0


        // let xPos = positionFromName(statement.subject, [...objects, ...obj]).x
        try{
            xPos = cols[statement.subject].bounds[0];
        }
        catch(err){
            try {
                xPos = cols[statement.target].bounds[0];
            }
            catch(e){
                // console.log(e)
                // console.log("objects in pos:", objects)
                try {
                    xPos = objects[objects.length - 1].position.x;
                }
                catch(ex){
                    xPos = 0;
                }

            }
        }

        // console.log("xpos:", xPos)

        // let totalY = startY + yInc + (vertPadding * (timestep + 1))
        let totalY = ((row + 1) * vertPadding) + vertPadding
        let sP = null;
        let tP = null;
        let dep_on = null;
        if (dependent_on !== null){
            // totalY = totalY + yInc + (vertPadding * dependent_on.count);
            xPos += horizPadding * dependent_on.count
            if (statement.subject === dependent_subject){

                if (dependent_on.first){
                    sP = "top";
                    tP = "bottom";
                    dep_on = dependent_on;

                }
                else {
                    sP = "left";
                    tP = "right";
                    // totalY = totalY + (yInc * 4);
                    dep_on = null
                }

            }

             // xPos += horizPadding * dependent_on.count

            // if (dependent_on.first){


            // }


        }


        if (dep_on === null){
            totalY = totalY + (yInc * 4);
        }

        // console.log("creating object:");
        // console.log("name:", objName);
        // console.log("sourcePosition:", sP);
        // console.log("targetPosition:", tP);
        // console.log("x:", xPos);
        // console.log("y:", totalY);
        // console.log("dep_on:", dep_on)
        // console.log("dependent_on:", dependent_on)
        let findObj = objFromName(statement.what, [...objects, ...obj])
        // console.log("findObj before:", findObj)
        // if (domains.indexOf(statement.what !== -1)){
        //     findObj = null
        // }
        // console.log(statement.what)
        // console.log("findObj:", findObj)
        if (findObj == null){
            console.log("name:", objName)
            console.log("position:", { x: xPos, y: totalY })
            console.log("dependent_on:", dependent_on)
            obj.push(
                createObject(
                    {
                        id: objId,
                        lineId: objLineId,
                        type: objType,
                        name: objName,
                        dependent_on: dep_on,
                        sourcePosition: sP,
                        targetPosition: tP,
                        position: { x: xPos, y: totalY },
                        style: ObjectTypes[statement.type].style,
                        labelStyle: ObjectTypes[statement.type].labelStyle
                    }
                )
            );

        }
        else {
            // whats.push(
            //     {id: findObj.id, label: findObj.name, type: findObj.type}
            // )
            objId = findObj.id
            objName = findObj.name
            objType = findObj.type
            objLineId = findObj.lineId
        }

        whats.push(
            {id: objId, label: objName, type: objType, lineId: objLineId}
        )


        let connId = uuid();

        if (dependent_on !== null){
            let connId = uuid();
            source = dependent_on.id
            if (source !== null){
                conn.push(
                    createConnection(
                        {
                            id: connId,
                            source: source,
                            target: idFromName(objName, [...objects, ...obj]),
                            // type: 'smoothstep',
                            animated: styleConfig.animated,
                            arrowHeadType: styleConfig.arrowhead,
                            labelBgPadding: [styleConfig.labelBgPaddingX, styleConfig.labelBgPaddingY],
                            labelBgBorderRadius: styleConfig.labelBgBorderRadius,
                            labelBgStyle: styleConfig.labelBgStyle,
                            labelStyle: styleConfig.labelStyle,
                            label: `(${dependent_on.name}) ${statement.verb}`,
                            style: styleConfig.style,
                            type: styleConfig.connectionType,
                            data: { text: `(${dependent_on.name}) ${statement.verb}`},
                        }
                    )
                )
            }


            connId = uuid();

            source = idFromName(statement.subject, [...objects, ...obj]);
            let dependent_subject_id = idFromName(dependent_subject, [...objects, ...obj]);
            // console.log("dependent_subject:", dependent_subject_id)
            // console.log("source:", source)
            if (dependent_subject_id !== source){
                if (source !== null){
                    conn.push(
                        createConnection(
                            {
                                id: connId,
                                source: source,
                                target: idFromName(objName, [...objects, ...obj]),
                                // type: 'smoothstep',
                                animated: styleConfig.animated,
                                arrowHeadType: styleConfig.arrowhead,
                                labelBgPadding: [styleConfig.labelBgPaddingX, styleConfig.labelBgPaddingY],
                                labelBgBorderRadius: styleConfig.labelBgBorderRadius,
                                labelBgStyle: styleConfig.labelBgStyle,
                                labelStyle: styleConfig.labelStyle,
                                label: `(${dependent_on.name}) ${statement.verb}`,
                                style: styleConfig.style,
                                type: styleConfig.connectionType,
                                data: { text: `(${dependent_on.name}) ${statement.verb}`},
                            }
                        )
                    )
                }

            }


        }
        else {
            // console.log("whats: ", [...whats])
            // console.log("source:", idFromName(statement.subject, [...objects, ...obj]))
            source = idFromName(statement.subject, [...objects, ...obj])
            if (source !== null){
                conn.push(
                    createConnection(
                        {
                            id: connId,
                            source: idFromName(statement.subject, [...objects, ...obj]),
                            target: idFromName(objName, [...objects, ...obj]),
                            // type: 'smoothstep',
                            animated: styleConfig.animated,
                            arrowHeadType: styleConfig.arrowhead,
                            label: statement.verb,
                            style: styleConfig.style,
                            labelBgPadding: [styleConfig.labelBgPaddingX, styleConfig.labelBgPaddingY],
                            labelBgBorderRadius: styleConfig.labelBgBorderRadius,
                            labelBgStyle: styleConfig.labelBgStyle,
                            labelStyle: styleConfig.labelStyle,
                            type: styleConfig.connectionType,
                            data: { text: statement.verb},
                        }

                    )
                )
            }

        }
    }
    if (statement.target !== null){
        // if (seen.indexOf(statement.target) == -1){
        //     obj.push(
        //         createObject(
        //             {
        //                 id: uuid(),
        //                 type: "input",
        //                 name: statement.target,
        //                 sourcePosition: null,
        //                 targetPosition: null,
        //                 dependent_on: null,
        //                 position: { x: startX + (horizPadding * obj.length), y: startY },
        //                 style: {
        //                     backgroundImage: "url(https://symbols.getvecta.com/stencil_0/0_start-end.6447d6d64e.svg)",
        //                     backgroundSize: "cover",
        //                     backgroundRepeat: "no-repeat",
        //                     backgroundPosition: "center",
        //                     backgroundColor: 'transparent',
        //                     border: 'hidden',
        //                     height: "50px",
        //                     width: "100px",
        //                     boxShadow: "0px 2px 9px -1px #888888",
        //                 },
        //                 labelStyle: { fill: '#f6ab6c', fontWeight: 700 },
        //                 className: "nodeBack",
        //             }
        //         )
        //     )
        //     newSeen.push(
        //         statement.target
        //     );
        // }
        if (Object.keys(cols).indexOf(statement.target) == -1){
            cols[statement.target] = {
                col: Object.keys(cols).length,
                bounds: [
                    horizPadding * Object.keys(cols).length,
                    (horizPadding * Object.keys(cols).length) + horizPadding
                ]
            }
            obj.push(
                createObject(
                    {
                        id: uuid(),
                        lineId: statement.lineId,
                        type: "input",
                        name: statement.target,
                        sourcePosition: null,
                        targetPosition: null,
                        dependent_on: null,
                        position: { x: cols[statement.target].bounds[0], y: vertPadding },
                        style: ObjectTypes["Terminator"].style,
                        labelStyle: ObjectTypes["Terminator"].labelStyle,
                        className: "nodeBack",
                    }

                )
            );
        }

    }

    row += 1;

    if (statement_options !== undefined){

        _.each(statement_options, (option, i) => {
            let dependent_on = {
                id: verbId,
                name: option.name,
                count: i,
                first: true
            };
            if (dependent_subject === null){
                dependent_subject = statement.subject
            }

            let new_row = row;

            _.each(option.statements, (statement) => {

                let pass = true;

                if (statement.tags !== undefined){
                    // if (statement.tags.indexOf("v2") !== -1){
                    //     pass = false;
                    // }
                }

                if (pass){
                    let dep = getObjectsConnectionsFromStatement(
                            statement,
                            [...objects, ...obj],
                            cols,
                            new_row,
                            // row,
                            seen,
                            timestep,
                            styleConfig,
                            domains,
                            dependent_on,
                            dependent_subject
                        )
                    // console.log(dep)
                    // console.log("dep whats:", dep['whats'])
                    dependent_on.id = dep['whats'][dep['whats'].length - 1].id
                    dependent_on.first = false
                    // console.log('dep', dep);
                    // console.log('new_obj:', new_obj);
                    // console.log('new_conn:', new_conn);
                    // console.log('new_seen:', new_seen);
                    // console.log('new_whats:', new_whats);
                    cols = dep['cols']
                    new_row = dep['row']
                    // row = dep['row']
                    obj = [ ...obj, ...dep['obj']];
                    conn = [...conn, ...dep['conn']];
                    seen = [...seen, ...dep['seen']];
                    whats = [...whats, ...dep['whats']];
                }
                // const {new_obj, new_conn, new_seen, new_whats} =

            })
        })

    }

    return {
        obj: obj,
        cols: cols,
        row: row,
        conn: conn,
        seen: newSeen,
        whats: whats
    }
}


const connectEndNodes = (whats, styleConfig) => {
    let conns = [];
    for (var i=0; i<whats.length - 1; i++){

        let ths = whats[i];
        let tht = whats[i+1];
        let connId = uuid();
        let source = null
        if (ths.type !== "output"){
            source = ths.id;
            if (source !== null){
                conns.push(
                    createConnection(
                        {
                            id: connId,
                            source: source,
                            target: tht.id,
                            // type: 'smoothstep',
                            animated: styleConfig.animated,
                            arrowHeadType: styleConfig.arrowhead,
                            label: "",
                            style: styleConfig.style,
                            labelBgPadding: [styleConfig.labelBgPaddingX, styleConfig.labelBgPaddingY],
                            labelBgBorderRadius: styleConfig.labelBgBorderRadius,
                            labelBgStyle: styleConfig.labelBgStyle,
                            labelStyle: styleConfig.labelStyle,
                            type: styleConfig.connectionType,
                            data: { text: ""},
                            // label: tht.label
                        }

                    )
                    )
            }

        }


    }
    // console.log("conns end:", conns);
    return conns
}


function handleStatement(
    statement, styleConfig, diagram,
    row, dependent_on=null,
    dependent_subject=null
){
        // let objects = [];
        // let connections = [];
        let ObjectTypes = styleConfig.ObjectTypes;

        let command = statement.command;
        let options = statement.options;
        let tags = statement.tags;
        let verbId = null;

        // alert(JSON.stringify(command))

        console.log("command: ", command)
        console.log("row: ", row)

        // Handle Subject
        if (command.subject !== null){
            if (!diagram.colExists(command.subject)){
                let obj = createObject(
                    {
                        id: uuid(),
                        lineId: command.lineId,
                        type: "input",
                        name: command.subject,
                        sourcePosition: null,
                        targetPosition: null,
                        dependent_on: null,
                        position: { x: null, y: null },
                        style: ObjectTypes["Terminator"].style,
                        labelStyle: ObjectTypes["Terminator"].labelStyle,
                        className: "nodeBack",
                    }
                )

                // diagram.addObject(
                //     command.subject,
                //     obj,
                //     row
                // )
                diagram.addCol(obj);

                // objects.push(
                //     obj
                // )
            }

        }

        // Handle target
        if (command.target !== null){
            if (!diagram.colExists(command.target)){
                let obj = createObject(
                    {
                        id: uuid(),
                        lineId: command.lineId,
                        type: "input",
                        name: command.target,
                        sourcePosition: null,
                        targetPosition: null,
                        dependent_on: null,
                        position: { x: null, y: null },
                        style: ObjectTypes["Terminator"].style,
                        labelStyle: ObjectTypes["Terminator"].labelStyle,
                        className: "nodeBack",
                    }

                )

                // objects.push(
                //     obj
                // );

                diagram.addCol(obj);
                // diagram.addObject(
                //     command.target,
                //     obj,
                //     row
                // )
            }

        }

        // Handle Verb and What
        if (command.verb !== null && command.what !== null){
            let objId = uuid();
            verbId = objId;
            let objName = command.what;
            let objType = null;
            let objLineId = command.lineId;
            let sP = null;
            let tP = null;
            let dep_on = null;
            let source = null;
            let connId = uuid();

            if (command.break){
                objType = "output";
            }
            connId = uuid();
            if (dependent_on !== null){
                source = dependent_on.id
                if (command.subject === dependent_subject){

                    if (dependent_on.first){
                        sP = "top";
                        tP = "bottom";
                        dep_on = dependent_on;

                    }
                    else {
                        sP = "left";
                        tP = "right";
                        dep_on = null
                    }

                }
            }
            else {
                source = idFromName(command.subject, diagram.objects)
            }
            let findObj = objFromName(command.what, diagram.objects)
            if (findObj == null){
                let obj = createObject(
                    {
                        id: objId,
                        lineId: objLineId,
                        type: objType,
                        name: objName,
                        dependent_on: dep_on,
                        sourcePosition: sP,
                        targetPosition: tP,
                        position: { x: null, y: null },
                        style: ObjectTypes[command.type].style,
                        labelStyle: ObjectTypes[command.type].labelStyle
                    }
                )

                // objects.push(
                //     obj
                // );
                let lane = command.subject;

                if (command.target !== null){
                    if (Object.keys(diagram.colMap).indexOf(command.target) !== -1){
                        lane = command.target
                        console.log("Setting lane:", lane)
                        console.log("cols", diagram.cols)
                        // diagram.addCol()
                        // console.log(diagram.colMap[lane])
                    }

                }

                diagram.addObject(
                    lane,
                    obj,
                    row
                )
            }

            else {

                objId = findObj.id
                objName = findObj.name
                objType = findObj.type
                objLineId = findObj.lineId
            }



            // Implement matching to existing objects





            // Connections

            if (dependent_on !== null){
                if (source !== null){
                    let connection = createConnection(
                        {
                            id: connId,
                            source: source,
                            target: idFromName(objName, diagram.objects),
                            // type: 'smoothstep',
                            animated: styleConfig.animated,
                            arrowHeadType: styleConfig.arrowhead,
                            labelBgPadding: [styleConfig.labelBgPaddingX, styleConfig.labelBgPaddingY],
                            labelBgBorderRadius: styleConfig.labelBgBorderRadius,
                            labelBgStyle: styleConfig.labelBgStyle,
                            labelStyle: styleConfig.labelStyle,
                            label: `(${dependent_on.name}) ${command.verb}`,
                            style: styleConfig.style,
                            type: styleConfig.connectionType,
                            data: { text: `(${dependent_on.name}) ${command.verb}`},
                        }
                    )

                    // connections.push(
                    //     connection
                    // )
                    diagram.addConnection(connection)
                }

                source = idFromName(command.subject, diagram.objects);
                let dependent_subject_id = idFromName(dependent_subject, diagram.objects);
                connId = uuid();
                if (dependent_subject_id !== source){
                    if (source !== null){
                            let connection = createConnection(
                                {
                                    id: connId,
                                    source: source,
                                    target: idFromName(objName, diagram.objects),
                                    // type: 'smoothstep',
                                    animated: styleConfig.animated,
                                    arrowHeadType: styleConfig.arrowhead,
                                    labelBgPadding: [styleConfig.labelBgPaddingX, styleConfig.labelBgPaddingY],
                                    labelBgBorderRadius: styleConfig.labelBgBorderRadius,
                                    labelBgStyle: styleConfig.labelBgStyle,
                                    labelStyle: styleConfig.labelStyle,
                                    label: `(${dependent_on.name}) ${command.verb}`,
                                    style: styleConfig.style,
                                    type: styleConfig.connectionType,
                                    data: { text: `(${dependent_on.name}) ${command.verb}`},
                                }
                            )

                            diagram.addConnection(connection)

                    }
                }
            }
            else {
                if (source !== null){
                    let connection = createConnection(
                        {
                            id: connId,
                            source: idFromName(command.subject, diagram.objects),
                            target: idFromName(objName, diagram.objects),
                            // type: 'smoothstep',
                            animated: styleConfig.animated,
                            arrowHeadType: styleConfig.arrowhead,
                            label: command.verb,
                            style: styleConfig.style,
                            labelBgPadding: [styleConfig.labelBgPaddingX, styleConfig.labelBgPaddingY],
                            labelBgBorderRadius: styleConfig.labelBgBorderRadius,
                            labelBgStyle: styleConfig.labelBgStyle,
                            labelStyle: styleConfig.labelStyle,
                            type: styleConfig.connectionType,
                            data: { text: command.verb},
                        }

                    )

                    // connections.push(
                    //     connection
                    // )
                    diagram.addConnection(connection)
                }
            }





        }



        row += 1

        if (options !== undefined){

            _.each(options, (option, i) => {
                let dependent_on = {
                    id: verbId,
                    name: option.name,
                    count: i,
                    first: true
                };
                if (dependent_subject === null){
                    dependent_subject = command.subject
                }

                let new_row = row;
                _.each(option.statements, (statement, x) => {

                    let statement_diagram = handleStatement(
                            statement,
                            styleConfig,
                            diagram,
                            new_row,
                            dependent_on,
                            dependent_subject
                        )
                    diagram = statement_diagram
                    diagram.connections = [...diagram.connections, ...connectEndNodes(diagram.objsAdded, styleConfig)]
                    dependent_on.id = diagram.objects[diagram.objects.length - 1].id
                    dependent_on.first = false


                })
            })

        }

        return diagram
}


const getElements = (sequence, styleConfig, filterConfig, domains) => {
    // window.localStorage.setItem('sequence', sequence);
    // window.localStorage.setItem('styleConfig', JSON.stringify(styleConfig, null, 4));
    // window.localStorage.setItem('filterConfig', JSON.stringify(filterConfig, null, 4));
    // window.localStorage.setItem('domains', JSON.stringify(domains, null, 4));
    let hiddenTags = []
    let showTags = []
    let showUntagged = true

    if (filterConfig !== undefined){
        hiddenTags = filterConfig.hide
        showTags = filterConfig.show
        showUntagged = filterConfig.showUntagged
    }



    let current_diagram = new Diagram(domains);
    try{
        sequence.sort(function(a, b){
            return a.timestep - b.timestep
        })

        _.each(sequence, (scene) => {
            _.each(scene.statements, (statement, i) => {

                // console.log("diagram: ", diagram)
                let tags = statement.tags;
                let show = tags.some(r=> showTags.includes(r))
                let hide = tags.some(r=> hiddenTags.includes(r))

                if (!hide && (show || showUntagged)){
                    const diagram = handleStatement(
                        statement,
                        styleConfig,
                        current_diagram,
                        i
                    )

                    current_diagram = diagram
                    console.log("current_diagram.objsAdded:", current_diagram.objsAdded)
                    current_diagram.connections = [...current_diagram.connections, ...connectEndNodes(current_diagram.objsAdded, styleConfig)]
                    current_diagram.objsAdded = []
                }



                // console.log(JSON.stringify(statement, null, 4))

            })
        })
        let horizPadding = parseFloat(styleConfig.horizPadding);
        let vertPadding = parseFloat(styleConfig.vertPadding);
        current_diagram.getPositionedObjects(horizPadding, vertPadding)
    }
    catch(err){
        console.log(err)
    }
    let objects = current_diagram.positionedObjects.map(function(x, i){
        x.data.label = (
            <div key={i} style={{width: '100%', position: 'absolute', background: 'transparent', height: "100%", left: 0, top: 0}}>
            <svg
                style={{
                    width: '75%',
                    height: '100%',
                    display: 'flex',
                    overflow: 'scroll',
                    position: 'relative',
                    marginLeft: 'auto',
                    marginRight: 'auto'
                    }}
                width="90%"
                height="100%"
                viewBox="0 0 300 24"
                >

                <text textAnchor="middle"

                      style={{
                          textShadow: 'rgb(187, 186, 186) -2px 1px 4px',
                          // textAnchor: 'inherit',

                          color: 'white',
                          fontSize: '3em',
                          dominantBaseline: "middle",
                          textAnchor: "middle"
                          }}
                        font="24px Helvetica, Arial"
                        stroke="none"
                        fill="black"

                        lengthAdjust="spacing"
                        x={"50%"}
                        y={"50%"}


                >
                <tspan>
                {`${x.data.label}`}
                </tspan>
                </text>

            </svg>
            </div>

        )
        return x;
    })
    return [...objects, ...current_diagram.connections]
}

const getElements2 = (sequence, styleConfig, filterConfig, domains) =>{
    console.log("getElements")
    let objects = [];
    let connections = [];
    let seenList = [];
    let whatsList = [];
    let initial_cols = {};
    let initial_row = 0;


    try{
        sequence.sort(function(a, b){
            return a.timestep - b.timestep
        })

        for (var i in sequence){
            let scene = sequence[i];
            for (var j in scene.statements){
                let statement = scene.statements[j];
                let pass = true;
                if (Object.keys(filterConfig).length > 0){
                    if (!filterConfig.showUntagged){
                        pass = false;
                    }



                    if (statement.tags !== undefined){
                        // if (statement.tags.indexOf("v2") !== -1){
                        //     pass = false;
                        // }
                        for (var t = 0; t < statement.tags.length; t++){
                            let tag = statement.tags[t];
                            if (filterConfig.hide.indexOf(tag) !== -1){
                                pass = false
                            }
                            if (filterConfig.show.indexOf(tag) !== -1){
                                pass = true
                            }
                        }

                    }
                }

                if (pass){
                    const {obj, conn, cols, row, seen, whats} = getObjectsConnectionsFromStatement(
                        statement,
                        objects,
                        initial_cols,
                        initial_row,
                        seenList,
                        scene.timestep,
                        styleConfig,
                        domains
                    )
                    initial_cols = cols
                    initial_row = row
                    objects = [...objects, ...obj];
                    connections = [...connections, ...conn];
                    seenList = [...seenList, ...seen];
                    whatsList = [...whatsList, ...whats];
                }

            }
            connections = [...connections, ...connectEndNodes(whatsList, styleConfig)]
        }

        objects = objects.map(function(x, i){
            x.data.label = (
                <div key={i} style={{width: '100%', position: 'absolute', background: 'transparent', height: "100%", left: 0, top: 0}}>
                <svg
                    style={{
                        width: '75%',
                        height: '100%',
                        display: 'flex',
                        overflow: 'scroll',
                        position: 'relative',
                        marginLeft: 'auto',
                        marginRight: 'auto'
                        }}
                    width="90%"
                    height="100%"
                    viewBox="0 0 300 24"
                    >

                    <text textAnchor="middle"

                          style={{
                              textShadow: 'rgb(187, 186, 186) -2px 1px 4px',
                              // textAnchor: 'inherit',

                              color: 'white',
                              fontSize: '3em',
                              dominantBaseline: "middle",
                              textAnchor: "middle"
                              }}
                            font="24px Helvetica, Arial"
                            stroke="none"
                            fill="black"

                            lengthAdjust="spacing"
                            x={"50%"}
                            y={"50%"}


                    >
                    <tspan>
                    {`${x.data.label}`}
                    </tspan>
                    </text>

                </svg>
                </div>

            )
            return x;
        })
    }
    catch(e){
        console.log(e);
    }

    // console.log("objects:", objects);
    // console.log("connections:", connections)

    return [...objects, ...connections]

}



const dagreGraph = new dagre.graphlib.Graph();

dagreGraph.setDefaultEdgeLabel(() => ({}))


const getLayoutedElements = (elements, direction = 'TB') => {
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction });

  elements.forEach((el) => {
    if (isNode(el)) {
      dagreGraph.setNode(el.id, { width: 150, height: 50 });
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });

  dagre.layout(dagreGraph);

  return elements.map((el, x) => {

    if (isNode(el)) {

      const nodeWithPosition = dagreGraph.node(el.id);
      if (el.targetPosition == null){
          el.targetPosition = "top";
      }
      if (el.sourcePosition == null){
          el.sourcePosition = "bottom";
      }
      if (el.dependent_on !== null){
          el.targetPosition = "top";
          el.sourcePosition = "bottom";
          if (x % 2 == 0){
              el.position = {
                x: el.position.x - (25 * x),
                y: el.position.y + (20 * x),
              };
          }
          else {
              el.position = {
                x: el.position.x + (25 * x),
                y: el.position.y + (20 * x),
              };
          }

          // el.position = {
          //   x: nodeWithPosition.x + Math.random() / 1000,
          //   y: nodeWithPosition.y,
          // };
      }

      if (autoLayout){
          el.position = {
            x: nodeWithPosition.x + Math.random() / 1000,
            y: nodeWithPosition.y,
          };
      }
      // el.position = {
      //   x: nodeWithPosition.x + Math.random() / 1000,
      //   y: nodeWithPosition.y,
      // };
      // el.targetPosition = isHorizontal ? 'left' : 'top';
      // el.sourcePosition = isHorizontal ? 'right' : 'bottom';
      // // unfortunately we need this little hack to pass a slighltiy different position
      // // in order to notify react flow about the change
      // if (!("position" in el)){
      // }

      // el.position = {
      //   x: nodeWithPosition.x + Math.random() / 1000,
      //   y: nodeWithPosition.y,
      // };
      // el.position = {
      //   x: el.position.x + Math.random() / 1000,
      //   y: el.position.y,
      // };
      // console.log("el:", el);

    }

    return el;
  });
};

// const areEqual = (prevProps, nextProps) => {
//     return (
//             prevProps.sequence === nextProps.sequence &&
//             prevProps.domains === nextProps.domains &&
//             prevProps.styleConfig === nextProps.styleConfig &&
//             prevProps.filterConfig === nextProps.filterConfig
//         )
// };

const LayoutFlow = React.memo(props => {
    // console.log("props:", props);
    // const initialElements = (props) => {
    //
    //     if (props.sequence !== null){
    //         return getElements(props.sequence);
    //     }
    //     return []


    // }


  const [rfInstance, setRfInstance] = useState(null);
  const layoutedElements = getLayoutedElements(getElements(props.sequence, props.styleConfig, props.filterConfig, props.domains));
  const [elements, setElements] = useState(layoutedElements);
  // const [seq, setSeq] = useState(props.sequence);
  // const [domains, setDomains] = useState(props.domains);
  // const [styleConfig, setStyleConfig] = useState(props.styleConfig);
  // const [filterConfig, setFilterConfig] = useState(props.filterConfig);
  const [data, setData] = useState(props.data);

  const [focusNodeInput, setFocusNodeInput] = useState(props.focusNodeInput);

  const onPositionForExport = useCallback(()=>{
      if (rfInstance) {
          rfInstance.fitView();
          let obj = rfInstance.toObject();
          rfInstance.setTransform({ x: 10, y: obj.position[1], zoom: obj.zoom });
          obj = rfInstance.toObject();
          const flow = {...obj, elements: _.map(obj.elements, (el) => {
                  return _.omit(el, ["data"])
              })}
          // console.log("flow:", flow);
          localforage.setItem(flowKey, flow);
      }

  }, [rfInstance])

  const onSave = useCallback(() => {
      if (rfInstance) {

        const obj = rfInstance.toObject();



        const flow = {...obj, elements: _.map(obj.elements, (el) => {
                return _.omit(el, ["data"])
            })}

        var flowVal = localStorage.getItem( 'flow' );

        // if (flow.elements.length > 0){
        //
        // }
        window.localStorage.setItem('objelements', JSON.stringify(obj.elements, null, 4));
        window.localStorage.setItem('flow', JSON.stringify(flow, null, 4));
        // console.log("flow:", flow);
        localforage.setItem(flowKey, flow);

      }
      }, [rfInstance])

  // const [styleConfig, setStyleConfig] = useState(props.styleConfig);


  // useEffect(() => {
  //   setMaxZoom(100000);
  // }, [maxZoom]);

//   useEffect(() => {
//   // if (props.sequence !== seq) {
//   if (!_.isEqual(props.sequence, seq)){
//     setSeq(props.sequence);
//     console.log("sequence change")
//     setElements(getLayoutedElements(getElements(props.sequence, props.styleConfig, props.filterConfig, props.domains)));
//     if (rfInstance) {
//         rfInstance.fitView();
//         const obj = rfInstance.toObject();
//         // rfInstance.setTransform({ x: 10, y: obj.position[1], zoom: obj.zoom });
//         onSave();
//     }
//   }
// }, [props.sequence]);


useEffect(() => {
// if (props.sequence !== seq) {
if (!_.isEqual(props.focusNodeInput, focusNodeInput)){
  setFocusNodeInput(props.focusNodeInput);
  console.log("data change")

  // alert("Flow: " + focusNodeInput.toString())
  // setElements(getLayoutedElements(getElements(props.data.sequence, props.data.styleConfig, props.data.filterConfig, props.data.domains)));
  onSave();
  // if (rfInstance) {
  //     // rfInstance.fitView();
  //     const obj = rfInstance.toObject();
  //     // rfInstance.setTransform({ x: 10, y: obj.position[1], zoom: obj.zoom });
  //     onSave();
  // }
}
}, [props.focusNodeInput]);


useEffect(() => {
// if (props.sequence !== seq) {
if (!_.isEqual(props.data, data)){
  setData(props.data);
  console.log("data change")
  setElements(getLayoutedElements(getElements(props.data.sequence, props.data.styleConfig, props.data.filterConfig, props.data.domains)));
  onSave();
  // if (rfInstance) {
  //     // rfInstance.fitView();
  //     const obj = rfInstance.toObject();
  //     // rfInstance.setTransform({ x: 10, y: obj.position[1], zoom: obj.zoom });
  //     onSave();
  // }
}
}, [props.data]);

// useEffect(() => {
// // if (props.domains !== domains) {
// if (!_.isEqual(props.domains, domains)){
//   setDomains(props.domains);
//   console.log("domains change")
//   setElements(getLayoutedElements(getElements(props.sequence, props.styleConfig, props.filterConfig, props.domains)));
//   if (rfInstance) {
//       rfInstance.fitView();
//       const obj = rfInstance.toObject();
//       // rfInstance.setTransform({ x: 10, y: obj.position[1], zoom: obj.zoom });
//       onSave();
//   }
// }
// }, [props.domains]);

// useEffect(() => {
// // if (props.styleConfig !== styleConfig) {
// if (!_.isEqual(props.styleConfig, styleConfig)){
//   setStyleConfig(props.styleConfig);
//   console.log("styleconfig change")
//   setElements(getLayoutedElements(getElements(props.sequence, props.styleConfig, props.filterConfig, props.domains)));
//   if (rfInstance) {
//       rfInstance.fitView();
//       const obj = rfInstance.toObject();
//       // rfInstance.setTransform({ x: 10, y: obj.position[1], zoom: obj.zoom });
//   }
//   // setElements(getLayoutedElements(getElements(props.sequence)));
// }
// }, [props.styleConfig]);
//
// useEffect(() => {
// // if (props.filterConfig !== filterConfig) {
// if (!_.isEqual(props.filterConfig, filterConfig)){
//     console.log("props.filterConfig:", props.filterConfig)
//     console.log("filterConfig:", filterConfig)
//   setFilterConfig(props.filterConfig);
//   console.log("filterconfig change")
//   setElements(getLayoutedElements(getElements(props.sequence, props.styleConfig, props.filterConfig, props.domains)));
//   if (rfInstance) {
//       rfInstance.fitView();
//       const obj = rfInstance.toObject();
//       // rfInstance.setTransform({ x: 10, y: obj.position[1], zoom: obj.zoom });
//   }
//   // setElements(getLayoutedElements(getElements(props.sequence)));
// }
// }, [props.styleConfig]);

  const onConnect = (params) =>
    setElements((els) =>
      addEdge({ ...params}, els)
    );

  const onElementsRemove = (elementsToRemove) =>
    setElements((els) => removeElements(elementsToRemove, els));
  const onLayout = useCallback(
    (direction) => {
      const layoutedElements = getLayoutedElements(elements, direction);
      setElements(layoutedElements);
      onSave();
    },
    [elements]
  );


  const handleFocusNodeInput = useCallback(()=> {
      if (rfInstance) {
        return focusNodeInput
      }
      }, [rfInstance])
  // const { transform } = useZoomPanHelper();


  const handleFitView = useCallback(()=> {
      if (rfInstance) {
        const obj = rfInstance.toObject();
        const flow = {...obj, elements: _.map(obj.elements, (el) => {
                return _.omit(el, ["data"])
            })}
        // console.log("flow:", flow);
        localforage.setItem(flowKey, flow);
      }
      }, [rfInstance])

  const onRestore = useCallback(() => {
    const restoreFlow = async () => {
      const flow = await localforage.getItem(flowKey);
      // console.log("flow:", flow);
      if (flow) {
        const [x = 0, y = 0] = flow.position;
        setElements(flow.elements || []);
        // const transform = rfInstance.setTransform({
        //         x: x,
        //         y: y,
        //         zoom: flow.zoom || 0
        //     })
        // transform({ x, y, zoom: flow.zoom || 0 });
      }
    };

    restoreFlow();
  }, [setElements]);

  const onLoad = (reactFlowInstance) => {
      // alert(JSON.stringify(filterConfig))
      reactFlowInstance.fitView()
      setRfInstance(reactFlowInstance)
  };
  return (
    <div className="layoutflow">
      <ReactFlowProvider>
        <ReactFlow
          elements={elements}
          onConnect={onConnect}
          onElementsRemove={onElementsRemove}
          onElementClick={onElementClick}
          onNodeDragStop={onNodeDragStop}
          onLoad={onLoad}
          snapToGrid={true}
          edgeTypes={edgeTypes}
          key="edges"
          maxZoom={10}
          minZoom={.1}
        >



        <ZoomHelper focusNodeInput={focusNodeInput}/>
        <div style={{
            display: "none"
            }} className="save__controls">


          <button onClick={onSave}>save</button>
          <button onClick={onRestore}>restore</button>
          <button id="leftAlignDiagram" onClick={onPositionForExport}>left align</button>
        </div>
        {/*

        */}

        </ReactFlow>
            <MiniMap />
          <Controls onFitView={handleFitView} />

          <Background variant="lines" color="red" size={0} style={{backgroundColor: data.styleConfig.background}} />

      </ReactFlowProvider>
    </div>
  );
});
export default LayoutFlow;
