var _ = require('lodash');


export default class Diagram {
    constructor(domains){
        this.domains = domains
        this.currentRow = 0
        this.objsAdded = []
        this.lastDomain = null
        this.cols = []
        this.colMap = {}
        this.objects = []
        this.positionedObjects = []
        this.connections = []
        this.rows = []
        this.initMapFromDomains(domains)
    }

    getPositionedObjects(horizPadding, vertPadding){
        let startX = 0;
        this.positionedObjects = []
        _.each(this.cols, (col, i) => {
            let obj = {...col}
            let nowX = startX + ( i  * horizPadding)
            let colWidth = this.colMap[obj.name].width
            this.colMap[obj.name].startX = nowX
            obj.position = {
                x: nowX,
                y: 0
            }
            startX = nowX + (horizPadding * (colWidth - 1))
            this.positionedObjects.push(obj)
        })
        _.each(this.rows, (rowObjs, i) => {
            _.each(rowObjs, (rowObj, x) => {
                let obj = rowObj["obj"]
                let col = rowObj["col"]
                let colWidth = this.colMap[col].width
                let colPos = this.colMap[col].currentPos
                let colOrder = this.colMap[col].order
                let startX = this.colMap[col].startX
                let modifier = 0;
                if (i % 2 !== 0){
                    modifier = horizPadding * .5
                }
                obj.position = {
                    x: startX + (horizPadding * colPos) + modifier,
                    y: (i + 1) * vertPadding
                }
                this.colMap[col].currentPos = colPos + 1
                if (this.colMap[col].currentPos == colWidth){
                    this.colMap[col].currentPos = 0
                }
                this.positionedObjects.push(obj)

            })
        })


    }

    colExists(name){
        let colNames = _.map(this.cols, (col) => {
            return col.name
        })
        return colNames.indexOf(name) !== -1
    }

    addConnection(conn){
        this.connections = [...this.connections, conn]
    }

    addCol(obj){
        console.log("obj.name:", obj.name)
        console.log("domains:", this.domains)

        if (this.domains.indexOf(obj.name) !== -1){
            this.colMap[obj.name] = {
                width: 0,
                currentPos: 0,
                order: this.cols.length
            }
            this.cols = [...this.cols, obj]
            this.objects = [...this.objects, obj]
            this.objsAdded = [...this.objsAdded, obj]
        }
    }

    addObject(domain, obj, row){
        // this.domainMap[domain].rows[row]
        // console.log(this.domainMap)
        // console.log(domain)
        // console.log(row)

        console.log("add object")
        console.log("domain:", domain)
        console.log("row:", row)
        // console.log("obj:", obj)


        if (Object.keys(this.domainMap).indexOf(domain) == -1){
            if (this.lastDomain !== null) {
                domain = this.lastDomain
            }
        }
        else {
            this.lastDomain = domain
        }


        let rows = this.domainMap[domain].rows;

        row = row.toString()

        if (Object.keys(rows).indexOf(row) == -1){
            this.domainMap[domain].rows[row] = []
        }
        this.domainMap[domain].rows[row].push(obj)


        console.log(typeof(this.rows[row]))
        if (typeof(this.rows[row]) !== "object"){
            this.rows[row] = []
        }

        this.rows[row].push(
            {
                "col": domain,
                "obj": obj
            }
        )

        let colRow = this.domainMap[domain].rows[row]
        if (colRow.length > this.colMap[domain].width){
            this.colMap[domain].width = colRow.length
        }
        this.objects = [...this.objects, obj]
        this.objsAdded = [...this.objsAdded, obj]

    }

    initMapFromDomains(domains) {
        let domainMap = {}
        _.each(domains, (domain) => {
            domainMap[domain] = {
                rows: {}
            }
        })
        this.domainMap = domainMap
    }

}
