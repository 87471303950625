import React from 'react';
import { render } from 'react-dom';
import _ from 'lodash'
import { slide as Menu } from "react-burger-menu";
import Monaco from "./Monaco";
import StyleEditor from "./StyleEditor";
import FilterEditor from "./FilterEditor";
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG } from 'react-component-export-image';
import download from 'downloadjs';
import localforage from 'localforage';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import ReactDetachableWindow from 'react-detachable-window'


const useStyles = theme => ({
  formControl: {
    margin: theme.spacing(.5),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
});


localforage.config({
  name: 'autoflow',
  storeName: 'flows',
});

const flowKey = 'my-flow';


// const user = 1;

var styles = {
  bmBurgerButton: {
      zIndex: 1000,
      position: "fixed",
      width: "30px",
      height: "24px",
      right: "36px",
      top: "10px"
  },

}


class ControlPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        downloadLink: "",
        modalIsOpen: false,
        modalType: "sequence",
        selectValue: "Editor",
        modalTextValue: "",
        sequence: {},
        sequences: [],
        presets: [],
        themes: [],
        objecttypes: [],
        data: [],
        sequenceId: "",
        inputValue: [],
        outputValue: [],
        styleConfig: {},
        filterConfig: {},
    }

    this.focusNode = this.focusNode.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
    this.newSequence = this.newSequence.bind(this)
    this.handleModalTextChange = this.handleModalTextChange.bind(this)
    this.buttonClick = this.buttonClick.bind(this)
    this.renderSequenceSelect = this.renderSequenceSelect.bind(this)
    this.handleSequenceSelectChange = this.handleSequenceSelectChange.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.editName = this.editName.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.handleModalTextChange = this.handleModalTextChange.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleStyleChange = this.handleStyleChange.bind(this)
    this.handleInputValue = this.handleInputValue.bind(this)
  }

  focusNode = lineNumber => {

      this.props.focusNode(lineNumber);
  }
  handleInputValue = inputValue => {
      console.log("controlpanel handleInputValue")
      console.log(inputValue)
      this.setState({
          inputValue: inputValue.src,
          outputValue: inputValue.expanded_src,
          domains: inputValue.domains
      },()=>{
          this.props.handleInputValue(inputValue)
      })
  }

  handleStyleChange = styleConfig => {
      this.setState({
          styleConfig: styleConfig
      }, ()=>{
          this.props.handleStyleChange(styleConfig)
      })
  }

  handleFilterChange = filterConfig => {
      this.setState({
          filterConfig: filterConfig
      }, ()=>{
          this.props.handleFilterChange(filterConfig)
      })
  }

  componentDidMount(){
      this.editorRef = this.props.getEditorRef();
      this.filtersRef = this.props.getFiltersRef();
      this.styleRef = this.props.getStyleRef();
      this.api = this.props.api;
      this.setState({
          sequence: this.props.sequence,
          sequenceId: this.props.sequenceId,
          sequences: this.props.sequences,
          presets: this.props.presets,
          themes: this.props.themes,
          objecttypes: this.props.objecttypes,
          inputValue: this.props.inputValue,
          outputValue: this.props.outputValue
      }, ()=>{
          this.props.setEditorRef(this.editorRef)
          this.props.setStyleRef(this.styleRef)
          this.props.setFiltersRef(this.filtersRef)
      })
  }
  componentWillReceiveProps(nextProps){

      if (nextProps.api !== this.api){
          this.api = nextProps.api
      }

      if (nextProps.sequence !== this.state.sequence){
          this.setState({
              sequence: nextProps.sequence
          })
      }
      if (nextProps.sequences !== this.state.sequences){
          this.setState({
              sequences: nextProps.sequences
          })
      }
      if (nextProps.presets !== this.state.presets){
          this.setState({
              presets: nextProps.presets
          })
      }
      if (nextProps.themes !== this.state.themes){
          this.setState({
              themes: nextProps.themes
          })
      }
      if (nextProps.objecttypes !== this.state.objecttypes){
          this.setState({
              objecttypes: nextProps.objecttypes
          })
      }
      if (nextProps.data !== this.state.data){
          this.setState({
              data: nextProps.data
          })
      }
      if (nextProps.inputValue !== this.state.inputValue){
          this.setState({
              inputValue: nextProps.inputValue
          })
      }
      if (nextProps.outputValue !== this.state.outputValue){
          this.setState({
              outputValue: nextProps.outputValue
          })
      }
      if (nextProps.sequenceId !== this.state.sequenceId){
          this.setState({
              sequenceId: nextProps.sequenceId
          })
      }
  }

  async newSequence(){
      if (this.state.modalIsOpen){
          let data = {
              title: this.state.modalTextValue,
              owner: {
                  id: this.props.userId
              },
              code: {
                  json: [
                      {
                          "statements": [],
                          "timestep": 0
                      }
                  ]
              },
              style_json: {},
              object_types_json: [],
              filters_json: {}
          }
          await this.api.post(data, `sequence`).then(
              ret => {
                  this.props.fetchSequence(ret.id);
              }

          )
          this.setState({
              modalIsOpen: false
          })
      }
      else {
          this.setState({
              modalIsOpen: true,
              modalType: "newsequence",
              modalTextValue: ""
          })
      }
  }

  async editName(){
      if (this.state.modalIsOpen){
          let data = {
              title: this.state.modalTextValue
          }
          await this.props.editName(data, this.state.sequence.id)
          this.setState({
              modalIsOpen: false
          })
      }
      else {
          this.setState({
              modalIsOpen: true,
              modalType: "sequence",
              modalTextValue: ""
          })
      }
  }


  handleClick = async(kind) => {
    let leftAlignDiagram = document.getElementById("leftAlignDiagram")
    leftAlignDiagram.click();
    setTimeout(async()=>{
        let el = document.getElementsByClassName("layoutflow")[0]
        const flow = await localforage.getItem(flowKey);
        let x = [];
        let y = [];
        _.each(flow.elements, (e) =>{
            if (Object.keys(e).indexOf("position") !== -1){
                x.push(e.position.x);
                y.push(e.position.y);
            }
        })
        let minX = Math.min(...x);
        let maxX = Math.max(...x);
        let minY = Math.min(...y);
        let maxY = Math.max(...y);
        let width = maxX - ((1/flow.zoom)*flow.position[0]);
        let height = el.offsetHeight - ((1/flow.zoom)*flow.position[1]);

        function filter(node){
            return node.className !== "react-flow__controls";
        }
        if (kind === "JPEG"){
            htmlToImage.toJpeg(el, {
                filter: filter,
                width: width,
                height: height
            })
              .then(function (dataUrl) {
                download(dataUrl, 'sequence.jpg');
              });
        }
        if (kind === "PNG"){
            htmlToImage.toPng(el, {
                filter: filter,
                width: width,
                height: height
            })
              .then(function (dataUrl) {
                download(dataUrl, 'sequence.png');
              });
        }
        if (kind === "SVG"){
            htmlToImage.toSvg(el, {
                filter: filter,
                width: width,
                height: height
            })
              .then(function (dataUrl) {
                download(dataUrl, 'sequence.svg');
              });
        }

    }, 1000)

  };

  renderSequenceSelect(){
    const { classes } = this.props;
      return (
          <FormControl variant="filled" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">Sequence</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={this.state.sequenceId}
                  onChange={this.props.handleSequenceSelectChange}
                  label="Sequence"
                >
                {_.map(this.state.sequences, (seq) => {
                    return <MenuItem key={seq.id} value={seq.id}>{seq.title}</MenuItem>
                })}

                </Select>
            </FormControl>
      )
  }


  handleSelectChange(evt){
      this.setState({
          selectValue: evt.target.value
      }, ()=>{
          if (this.state.selectValue == "Editor"){
              this.editorRef.current.showCodeButtons()
          }
          else {
              this.editorRef.current.hideCodeButtons()
          }

      })
  }

  handleSequenceSelectChange(evt){
      let sequence = _.filter(this.state.sequences, (seq) => {
          return seq.id === parseInt(evt.target.value)
      })[0]

      this.setState({
          sequence: sequence,
          sequenceId: sequence.id,
          inputValue: sequence.code.json
      }, ()=>{
          setTimeout(()=>{
              this.buttonClick();
          }, 500)
      })
  }

  buttonClick(){
      this.setState({
          inputValue: this.editorRef.current.state.src,
          outputValue: this.editorRef.current.state.expanded_src,
          domains: this.editorRef.current.state.domains
      },()=>{
          this.filtersRef.current.buttonClick();
          this.styleRef.current.buttonClick();
          this.editorRef.current.buttonClick();
          this.props.buttonClick();
      })

  }


  closeModal(){
      this.setState({
          modalIsOpen: false
      })

  }
  handleModalTextChange(evt) {
      this.setState({
          modalTextValue: evt.target.value
      })

  }

  render() {

      const { classes } = this.props;

      var reattachButton = (<button style={{position: "absolute", top: 0, left: 0, zIndex: 100000}} type='button'>Close!</button>)
    var detachButton = (<button style={{position: "absolute", top: 0, left: 0, zIndex: 100000}} type='button'>Detach!</button>)

    return (
        <div>
        {this.props.fullScreen ? (

            <Menu isOpen={true} noOverlay width={"100vw"} itemListElement="div" styles={styles} right pageWrapId={"controlPanel"} outerContainerId={"App"}>
            <div id="controlPanel" className="controlPanel">
              <div id="AppHolder" className="AppHolder">
                  <div style={{textAlign: "center", paddingTop: 10}}>
                    <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                      <Button onClick={() => this.handleClick("JPEG")}>
                       Export As JPEG
                     </Button>
                     <Button onClick={() => this.handleClick("PNG")}>
                       Export As PNG
                     </Button>
                     <Button onClick={() => this.handleClick("SVG")}>
                       Export As SVG
                     </Button>
                    </ButtonGroup>
                 </div>

              {this.state.modalIsOpen && (
                  <div className="saveModal">
                  {this.state.modalType == "sequence" && (

                      <div style={{backgroundColor: "black", justifyContent: "center", alignItems: "center"}} className="StyleEditorRow">
                          <div style={{flex: 1, alignItems: "center"}} className="styleItem">
                              <Button variant="outlined" color="secondary" onClick={this.closeModal}>close</Button>

                          </div>
                          <div style={{flex: 2, alignItems: "center"}} className="styleItem">
                            <form style={{width: "100%"}} className={classes.root} noValidate autoComplete="off">
                            <TextField
                              value={this.state.modalTextValue}
                              onChange={this.handleModalTextChange}
                              id="standard-full-width"
                              label="Sequence Name"
                              variant="filled"
                              color="secondary"
                              fullWidth

                            />
                            </form>
                          </div>
                          <div style={{flex: 1, alignItems: "center"}} className="styleItem">
                            <Button variant="contained" color="primary" style={{margin: 10}} onClick={this.editName}>Submit</Button>
                          </div>
                       </div>

                  )}
                  {this.state.modalType == "newsequence" && (
                      <div style={{backgroundColor: "black", justifyContent: "center", alignItems: "center"}} className="StyleEditorRow">
                        <div style={{flex: 1, alignItems: "center"}} className="styleItem">
                            <Button variant="outlined" color="secondary" onClick={this.closeModal}>close</Button>
                        </div>

                        <div style={{flex: 2, alignItems: "center"}} className="styleItem">

                            <form style={{width: "100%"}} className={classes.root} noValidate autoComplete="off">
                            <TextField
                              value={this.state.modalTextValue}
                              onChange={this.handleModalTextChange}
                              id="standard-full-width"
                              label="Sequence Name"
                              variant="filled"
                              color="secondary"
                              fullWidth

                            />
                            </form>
                        </div>
                        <div style={{flex: 1, alignItems: "center"}} className="styleItem">
                            <Button variant="contained" color="primary" style={{margin: 10}} onClick={this.newSequence}>Submit</Button>
                        </div>

                       </div>

                  )}
                  </div>
              )}


                <div style={{justifyContent: 'center', margin: "0px"}} className="StyleEditorRow">
                        <div style={{flex: 2, alignItems: "center"}} className="styleItem">
                            <FormControl variant="filled" className={classes.formControl}>
                              <InputLabel id="demo-simple-select-outlined-label">Section</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    value={this.state.selectValue}
                                    onChange={this.handleSelectChange}
                                    label="Control Panel Panel"
                                  >
                                  <MenuItem value={"Editor"}>{"Editor"}</MenuItem>
                                  <MenuItem value={"Style"}>{"Style"}</MenuItem>
                                  <MenuItem value={"Filters"}>{"Filters"}</MenuItem>
                                  </Select>
                              </FormControl>
                        </div>

                        <div style={{flex: 4, alignItems: "center"}} className="styleItem">
                            {this.renderSequenceSelect()}
                        </div>

                        <div style={{marginRight: "10px", flex: 3, alignItems: "center"}} className="styleItem">

                        <ButtonGroup variant="contained" aria-label="contained button group">
                        <Button style={{width: "100%", height: "35px"}} onClick={this.newSequence}>New</Button>
                        <Button style={{width: "100%", height: "35px"}} onClick={this.editName}>Rename</Button>
                        <Button style={{width: "100%", height: "35px"}} onClick={this.buttonClick}>Apply</Button>
                        <Button style={{width: "100%", height: "35px"}} onClick={this.props.saveClick}>Save</Button>
                        </ButtonGroup>
                        </div>


                </div>

                    <div className="AppFlex">
                        <div
                            className="AppSegment"
                            style = {
                                this.state.selectValue == "Editor" ? {flex: 1} : {flex: 0}
                            }
                        >
                            <h2 style={{
                                paddingTop: 10,
                                color: "white"
                            }}>Editor</h2>


                            <Monaco
                                ref={this.editorRef}
                                focusNode={this.focusNode}
                                handleInputValue={this.handleInputValue}
                                data={this.state.inputValue}
                                mode={"EASY"}
                                download={this.props.download}
                                selectValue={this.state.selectValue}
                                classes={this.props.classes}

                              />
                        </div>
                        <div
                            className="AppSegment"
                            style = {
                                this.state.selectValue == "Filters" ? {flex: 1} : {flex: 0}
                            }
                        >
                        <h2 style={{
                            padding: 10,
                            color: "white"
                        }}>Filters</h2>
                        <FilterEditor
                            handleFilterChange={this.handleFilterChange}
                            ref={this.filtersRef}
                            sequence={this.state.sequence}
                            classes={this.props.classes}

                        />
                        </div>
                        <div
                            className="AppSegment"
                            style = {
                                 this.state.selectValue == "Style" ? {flex: 1} : {flex: 0}
                            }
                        >
                        <h2 style={{
                            padding: 10,
                            color: "white"
                        }}>Styles</h2>
                        <StyleEditor
                            handleStyleChange={this.handleStyleChange}
                            ref={this.styleRef}
                            sequence={this.state.sequence}
                            presets={this.state.presets}
                            themes={this.state.themes}
                            objecttypes={this.state.objecttypes}
                            createPreset={this.props.createPreset}
                            createTheme={this.props.createTheme}
                            classes={this.props.classes}
                        />
                        </div>
                    </div>
                    </div>
                    </div>
                    </Menu>


        ) : (
            <Menu noOverlay width={"800px"} itemListElement="div" styles={styles} right pageWrapId={"controlPanel"} outerContainerId={"App"}>

            <div id="controlPanel" className="controlPanel">
              <div id="AppHolder" className="AppHolder">
                  <div style={{textAlign: "center", paddingTop: 10}}>
                    <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                      <Button onClick={() => this.handleClick("JPEG")}>
                       Export As JPEG
                     </Button>
                     <Button onClick={() => this.handleClick("PNG")}>
                       Export As PNG
                     </Button>
                     <Button onClick={() => this.handleClick("SVG")}>
                       Export As SVG
                     </Button>
                    </ButtonGroup>
                 </div>

              {this.state.modalIsOpen && (
                  <div className="saveModal">
                  {this.state.modalType == "sequence" && (

                      <div style={{backgroundColor: "black", justifyContent: "center", alignItems: "center"}} className="StyleEditorRow">
                          <div style={{flex: 1, alignItems: "center"}} className="styleItem">
                              <Button variant="outlined" color="secondary" onClick={this.closeModal}>close</Button>

                          </div>
                          <div style={{flex: 2, alignItems: "center"}} className="styleItem">
                            <form style={{width: "100%"}} className={classes.root} noValidate autoComplete="off">
                            <TextField
                              value={this.state.modalTextValue}
                              onChange={this.handleModalTextChange}
                              id="standard-full-width"
                              label="Sequence Name"
                              variant="filled"
                              color="secondary"
                              fullWidth

                            />
                            </form>
                          </div>
                          <div style={{flex: 1, alignItems: "center"}} className="styleItem">
                            <Button variant="contained" color="primary" style={{margin: 10}} onClick={this.editName}>Submit</Button>
                          </div>
                       </div>

                  )}
                  {this.state.modalType == "newsequence" && (
                      <div style={{backgroundColor: "black", justifyContent: "center", alignItems: "center"}} className="StyleEditorRow">
                        <div style={{flex: 1, alignItems: "center"}} className="styleItem">
                            <Button variant="outlined" color="secondary" onClick={this.closeModal}>close</Button>
                        </div>

                        <div style={{flex: 2, alignItems: "center"}} className="styleItem">

                            <form style={{width: "100%"}} className={classes.root} noValidate autoComplete="off">
                            <TextField
                              value={this.state.modalTextValue}
                              onChange={this.handleModalTextChange}
                              id="standard-full-width"
                              label="Sequence Name"
                              variant="filled"
                              color="secondary"
                              fullWidth

                            />
                            </form>
                        </div>
                        <div style={{flex: 1, alignItems: "center"}} className="styleItem">
                            <Button variant="contained" color="primary" style={{margin: 10}} onClick={this.newSequence}>Submit</Button>
                        </div>

                       </div>

                  )}
                  </div>
              )}


                <div style={{justifyContent: 'center', margin: "0px"}} className="StyleEditorRow">
                        <div style={{flex: 2, alignItems: "center"}} className="styleItem">
                            <FormControl variant="filled" className={classes.formControl}>
                              <InputLabel id="demo-simple-select-outlined-label">Section</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-filled-label"
                                    id="demo-simple-select-filled"
                                    value={this.state.selectValue}
                                    onChange={this.handleSelectChange}
                                    label="Control Panel Panel"
                                  >
                                  <MenuItem value={"Editor"}>{"Editor"}</MenuItem>
                                  <MenuItem value={"Style"}>{"Style"}</MenuItem>
                                  <MenuItem value={"Filters"}>{"Filters"}</MenuItem>
                                  </Select>
                              </FormControl>
                        </div>

                        <div style={{flex: 4, alignItems: "center"}} className="styleItem">
                            {this.renderSequenceSelect()}
                        </div>

                        <div style={{marginRight: "10px", flex: 3, alignItems: "center"}} className="styleItem">

                        <ButtonGroup variant="contained" aria-label="contained button group">
                        <Button style={{width: "100%", height: "35px"}} onClick={this.newSequence}>New</Button>
                        <Button style={{width: "100%", height: "35px"}} onClick={this.editName}>Rename</Button>
                        <Button style={{width: "100%", height: "35px"}} onClick={this.buttonClick}>Apply</Button>
                        <Button style={{width: "100%", height: "35px"}} onClick={this.props.saveClick}>Save</Button>
                        </ButtonGroup>
                        </div>


                </div>

                    <div className="AppFlex">
                        <div
                            className="AppSegment"
                            style = {
                                this.state.selectValue == "Editor" ? {flex: 1} : {flex: 0}
                            }
                        >
                            <h2 style={{
                                paddingTop: 10,
                                color: "white"
                            }}>Editor</h2>


                            <Monaco
                                ref={this.editorRef}
                                focusNode={this.focusNode}
                                handleInputValue={this.handleInputValue}
                                data={this.state.inputValue}
                                mode={"EASY"}
                                download={this.props.download}
                                selectValue={this.state.selectValue}
                                classes={this.props.classes}

                              />
                        </div>
                        <div
                            className="AppSegment"
                            style = {
                                this.state.selectValue == "Filters" ? {flex: 1} : {flex: 0}
                            }
                        >
                        <h2 style={{
                            padding: 10,
                            color: "white"
                        }}>Filters</h2>
                        <FilterEditor
                            handleFilterChange={this.handleFilterChange}
                            ref={this.filtersRef}
                            sequence={this.state.sequence}
                            classes={this.props.classes}

                        />
                        </div>
                        <div
                            className="AppSegment"
                            style = {
                                 this.state.selectValue == "Style" ? {flex: 1} : {flex: 0}
                            }
                        >
                        <h2 style={{
                            padding: 10,
                            color: "white"
                        }}>Styles</h2>
                        <StyleEditor
                            handleStyleChange={this.handleStyleChange}
                            ref={this.styleRef}
                            sequence={this.state.sequence}
                            presets={this.state.presets}
                            themes={this.state.themes}
                            objecttypes={this.state.objecttypes}
                            createPreset={this.props.createPreset}
                            createTheme={this.props.createTheme}
                            classes={this.props.classes}
                        />
                        </div>
                    </div>
                    </div>
                    </div>

            </Menu>
        )}

        </div>
    );
  }
}

export default withStyles(useStyles)(ControlPanel);
