import React from 'react';
import logo from '../media/logo.svg';
import '../stylesheets/App.css';
import _ from 'lodash'
import {expand_command} from "../utils/TransformJSON"
import MonacoEditor from 'react-monaco-editor';
var pos = require('pos');


const options = {
  selectOnLineNumbers: true,
  tabSize: 2
};


var additional = [
    {
        "ingests": ['VBZ']
    },
    {
        "triggers": ['VBZ']
    },
    {
        "places": ['VBZ']
    },
    {
        "stores": ['VBZ']
    },
    {
        "returns": ['VBZ']
    },
    {
        "claim": ['VBZ']
    },
    {
        "requests": ['VBZ']
    },
    {
        "updates": ['VBZ']
    },
    {
        "posts": ['VBZ']
    },

]



var tagger = new pos.Tagger();
for (var i in additional){
    let add = additional[i]
    tagger.extendLexicon(add)
}




var tagMap = {


    // Determiner

    'DT': {description: "Determiner",
            color: "#02b06d",
            example:'the,some'},
    'PDT': {description: "Predeterminer",
            color: "#02a365",
            example:'all, both'},

    // Other

    'MD': {description: "Modal",
            color: "#f2ebf2",
            example:'can,should'},

    'EX': {description: "Existential there",
            color: "#f2e1f2",
            example:'there'},

    'POS': {description: "Possessive ending",
            color: "#f5e9f5",
            example:"'s"},


    'RP': {description: "Particle",
            color: "#f5e4f5",
            example:'up,off'},

    'UH': {description: "Interjection",
            color: "#f0e1f0",
            example:'oh, oops'},

    // Foreign

    'FW': {description: "Foreign Word",
            color: "#d481c7",
            example:'mon dieu'},

    // Countable

    'CD':{
        description: "Cardinal number",
        color: "#f6ffdb",
        example: "one,two"
    },

    'LS': {description: "List item marker",
            color: "#f1fad4",
            example:'1,One'},

    // Wh

    'WDT': {description: "Wh-determiner",
            color: "#5867a3",
            example:'which,that'},
    'WP': {description: "Wh pronoun",
            color: "#6171b0",
            example:'who,what'},
    'WP$': {description: "Possessive-Wh",
            color: "#5262a1",
            example:'whose'},
    'WRB': {description: "Wh-adverb",
            color: "#54639e",
            example:'how,where'},

    // Punc

    'SYM': {description: "Symbol",
            color: "#a6a6a6",
            example:'+,%,&'},

    ',': {description: "Comma",
            color: "#9e9b9b",
            example:','},
    '.': {description: "Sent-final punct",
            color: "#8c8b8b",
            example:'. ! ?'},
    ':': {description: "Mid-sent punct.",
            color: "#9c9898",
            example:': ; Ñ'},
    '$': {description: "Dollar sign",
            color: "#9c9a9a",
            example:'$'},
    '#': {description: "Pound sign",
            color: "#aba6a6",
            example:'#'},
    '"': {description: "quote",
            color: "#ada8a8",
            example:'"'},
    '(': {description: "Left paren",
            color: "#969393",
            example:'('},
    ')': {description: "Right paren",
            color: "#969292",
            example:')'},



    // Preposition

    'IN': {description: "Preposition",
            color: "#841fff",
            example:'of,in,by'},
    'TO': {description: "'to'",
            color: "#7317e3",
            example:'to'},

    // Adjective

    'JJ': {description: "Adjective",
            color: "#e39f17",
            example:'big'},
    'JJR': {description: "Adj., comparative",
            color: "#d69513",
            example:'bigger'},
    'JJS': {description: "Adj., superlative",
            color: "#ffb726",
            example:'biggest'},




    // Nouns

    'NN': {description: "Noun, sing. or mass",
            color: "#26baff",
            example:'dog'},
    'NNP': {description: "Proper noun, sing.",
            color: "#25aae8",
            example:'Edinburgh'},
    'NNPS': {description: "Proper noun, plural",
            color: "#35a6db",
            example:'Smiths'},
    'NNS': {description: "Noun, plural",
            color: "#24a3ed",
            example:'dogs'},

    'PRP$': {description: "Possessive pronoun",
            color: "#37daf0",
            example:"my,one's"},
    'PRP': {description: "Personal pronoun",
            color: "#3dd6eb",
            example:'I,you,she'},




    // Adverb

    'RB': {description: "Adverb",
            color: "#fc32d1",
            example:'quickly'},
    'RBR': {description: "Adverb, comparative",
            color: "#ed37c6",
            example:'faster'},
    'RBS': {description: "Adverb, superlative ",
            color: "#de23b6",
            example:'fastest'},




    // Verbs

    'VB': {description: "verb, base form",
            color: "#ff1900",
            example:'eat'},
    'VBD': {description: "verb, past tense",
            color: "#eb240e",
            example:'ate'},
    'VBG': {description: "verb, gerund",
            color: "#f53722",
            example:'eating'},
    'VBN': {description: "verb, past part",
            color: "#cf1e0a",
            example:'eaten'},
    'VBP': {description: "Verb, present",
            color: "#f5260f",
            example:'eat'},
    'VBZ': {description: "Verb, present",
            color: "#de1802",
            example:'eats'},



}


class Analysis extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            text: "",
            tags: [],
            domains: []
        }
        this.onChange = this.onChange.bind(this)
        this.getTags = this.getTags.bind(this)
    }

    componentDidMount(){
        let tags = this.getTags(this.props.initialText)
        this.setState({
            text: this.props.initialText,
            tags: tags,
            domains: this.props.domains
        })
    }

    onChange(e){
        let val = e.target.value
        let tags = this.getTags(val)
        this.setState({
            text: val,
            tags: tags
        })
    }


    getTags(v){
        let vals = v.split("\n")
        var tags = [];
        for (var x in vals){
            let val = vals[x]
            var words = new pos.Lexer().lex(val)

            var taggedWords = tagger.tag(words);

            for (var i in taggedWords) {
                var taggedWord = taggedWords[i];
                var word = taggedWord[0];
                var tag = taggedWord[1];
                console.log(word + " /" + tag);

                let description = "";
                let example = "";
                let color = ""

                if (Object.keys(tagMap).indexOf(tag) !== -1){
                    description = tagMap[tag].description;
                    example = tagMap[tag].example;
                    color = tagMap[tag].color;
                }

                tags.push(
                    {
                        word: word,
                        color: color,
                        tag: tag,
                        description: description,
                        example: example

                    }
                )
            }
            tags.push(
                {
                    newline: true
                }
            )
        }

        return tags
    }


    renderTags(){
        var tagwords = [];
        var tags = [];
        return (
            <div style={{flexWrap: "wrap",gap: 5,}} className="Tags">
                {_.map(this.state.tags, (tag)=> {
                    if (tag.newline === true){
                        let str = tags.join(" ")
                        let tw = tagwords;

                        tags = [];
                        tagwords = [];
                        return (
                            <>

                                <hr />
                                {tw}

                                <hr />
                            </>
                        )
                    }
                    tags.push(
                        tag.tag
                    )
                    // let color = "gray"
                    // if ([
                    //     'VB',
                    //     'VBD',
                    //     'VBG',
                    //     'VBN',
                    //     'VBP',
                    //     'VBZ'
                    // ].indexOf(tag.tag) !== -1){
                    //     color = "red"
                    // }
                    //
                    // if ([
                    //     'NN',
                    //     'NNP',
                    //     'NNPS',
                    //     'NNS'
                    //
                    // ].indexOf(tag.tag) !== -1){
                    //     color = "green"
                    // }
                    //
                    // if ([
                    //     'IN',
                    //     'TO'
                    //
                    // ].indexOf(tag.tag) !== -1){
                    //     color = "purple"
                    // }
                    tagwords.push(
                        <span style={{padding: 2, color: tag.color}}>{tag.word}&nbsp;</span>
                    )
                    return (
                        <div style={{
                            fontSize: 16,
                            flexWrap: "wrap",gap: 5,
                        }} className="TagContainer">
                            <div className="Word"><span>{tag.word}</span></div>
                            <div
                                style={{color: tag.color}}
                                className="Tag"><span>{tag.tag}</span></div>
                            <div
                            style={{color: "silver"}}
                             className="Description"><span>{tag.description}</span></div>
                            <div className="Example"><span>{tag.example}</span></div>
                        </div>
                    )
                })}
            </div>
        )
    }

    editorDidMount(editor, monaco) {
      // console.log('editorDidMount', editor);
      editor.focus();
    }

    renderAll(){
        return (
            <div style={{flexDirection: "column", flexWrap: "column",gap: 5,  height: "100%", overflow: "scroll"}} className="Tags">
                {_.map(tagMap, (tag, key)=> {
                    return (
                        <div style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center"

                        }} className="TagContainer">

                            <div className="Tag">
                                <span style={{
                                    color: tag.color,
                                    fontSize: 16
                                }}>
                                    {key}
                                </span>
                            </div>
                            <div className="Description">
                            <span style={{
                                fontSize: 16,
                                color: "silver"
                            }}>
                            {tag.description}
                            </span></div>
                            <div className="Example">
                            <span style={{fontSize: 16}}>
                            {tag.example}</span></div>
                        </div>
                    )
                })}
            </div>
        )
    }


    render(){
        return (
          <>
          <div className="AnalysisCol">
            <div  className="AnalysisRow">
              <div className="AnalysisCol">
                {this.renderTags()}
              </div>


            </div>
            <div className="AnalysisRow">

            <div className="AnalysisCol" style={{
                textAlign: "left"
            }}>

            <MonacoEditor

              language="json"
              theme="myCoolTheme"
              value={JSON.stringify(
                  expand_command(this.state.text, this.state.domains),
                  null,
                  4
              )}
              options={options}

              editorDidMount={this.editorDidMount}
            />
            </div>

                  <div className="AnalysisCol">

                  <div className="AnalysisCol" style={{
                      height: "50%"
                  }}>
                      {this.renderAll()}
                  </div>

                    <div className="AnalysisCol">
                      <div style={{
                          flex: 4,

                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center"
                      }}>
                        <textarea style={{
                            width: "100%",
                            height: "100%"
                        }} type="text" value={this.state.text} onChange={this.onChange} />
                      </div>
                      </div>
                  </div>
              </div>
          </div>


          </>
        );
    }
}

export default Analysis;
