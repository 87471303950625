import _ from 'lodash'

// const fs = require('fs');

// // console.log = () => {}

var pos = require('pos');


var additional = [
    {
        "ingests": ['VBZ']
    },
    {
        "triggers": ['VBZ']
    },
    {
        "places": ['VBZ']
    },
    {
        "stores": ['VBZ']
    },
    {
        "returns": ['VBZ']
    },
    {
        "claim": ['VBZ']
    },
    {
        "requests": ['VBZ']
    },
    {
        "updates": ['VBZ']
    },
    {
        "posts": ['VBZ']
    },

]


var tagMap = {
    'CC':{
        description: "Coord Conjuncn",
        example: "and,but,or"
    },
    'CD':{
        description: "Cardinal number",
        example: "one,two"
    },

    'DT': {description: "Determiner",
            example:'the,some'},
    'EX': {description: "Existential there",
            example:'there'},
    'FW': {description: "Foreign Word",
            example:'mon dieu'},
    'IN': {description: "Preposition",
            example:'of,in,by'},
    'JJ': {description: "Adjective",
            example:'big'},
    'JJR': {description: "Adj., comparative",
            example:'bigger'},
    'JJS': {description: "Adj., superlative",
            example:'biggest'},
    'LS': {description: "List item marker",
            xample:'1,One'},
    'MD': {description: "Modal",
            example:'can,should'},
    'NN': {description: "Noun, sing. or mass",
            example:'dog'},
    'NNP': {description: "Proper noun, sing.",
            example:'Edinburgh'},
    'NNPS': {description: "Proper noun, plural",
            example:'Smiths'},
    'NNS': {description: "Noun, plural",
            example:'dogs'},
    'POS': {description: "Possessive ending",
            example:"'s"},
    'PDT': {description: "Predeterminer",
            example:'all, both'},
    'PRP$': {description: "Possessive pronoun",
            example:"my,one's"},
    'PRP': {description: "Personal pronoun",
            example:'I,you,she'},
    'RB': {description: "Adverb",
            example:'quickly'},
    'RBR': {description: "Adverb, comparative",
            example:'faster'},
    'RBS': {description: "Adverb, superlative ",
            example:'fastest'},
    'RP': {description: "Particle",
            example:'up,off'},
    'SYM': {description: "Symbol",
            example:'+,%,&'},
    'TO': {description: "'to'",
            example:'to'},
    'UH': {description: "Interjection",
            example:'oh, oops'},
    'VB': {description: "verb, base form",
            example:'eat'},
    'VBD': {description: "verb, past tense",
            example:'ate'},
    'VBG': {description: "verb, gerund",
            example:'eating'},
    'VBN': {description: "verb, past part",
            example:'eaten'},
    'VBP': {description: "Verb, present",
            example:'eat'},
    'VBZ': {description: "Verb, present",
            example:'eats'},
    'WDT': {description: "Wh-determiner",
            example:'which,that'},
    'WP': {description: "Wh pronoun",
            example:'who,what'},
    'WP$': {description: "Possessive-Wh",
            example:'whose'},
    'WRB': {description: "Wh-adverb",
            example:'how,where'},
    ',': {description: "Comma",
            example:','},
    '.': {description: "Sent-final punct",
            example:'. ! ?'},
    ':': {description: "Mid-sent punct.",
            example:': ; Ñ'},
    '$': {description: "Dollar sign",
            example:'$'},
    '#': {description: "Pound sign",
            example:'#'},
    '"': {description: "quote",
            example:'"'},
    '(': {description: "Left paren",
            example:'('},
    ')': {description: "Right paren",
            example:')'},
}

var verbs = [
    'VB',
    'VBD',
    'VBG',
    'VBN',
    'VBP',
    'VBZ'
]

var nouns = [
    'NN',
    'NNP',
    'NNPS',
    'NNS'

]

var prepositions = [
    'IN',
    'TO'
]

function getTags(text){
    var tagger = new pos.Tagger();
    for (var i in additional){
        let add = additional[i]
        tagger.extendLexicon(add)
    }
    var tags = [];
    var words = new pos.Lexer().lex(text)
    var taggedWords = tagger.tag(words);
    let has_verbs = false;
    let plural_positions = [];
    for (var i in taggedWords) {
        var taggedWord = taggedWords[i];
        var word = taggedWord[0];
        var tag = taggedWord[1];
        let description = "";
        let example = "";

        if (Object.keys(tagMap).indexOf(tag) !== -1){
            description = tagMap[tag].description;
            example = tagMap[tag].example;
        }

        if (isVerb(tag)){
            has_verbs = true
        }

        if (tag == "NNS"){
            plural_positions.push(i)
        }

        tags.push(
            {
                word: word,
                tag: tag,
                description: description,
                example: example

            }
        )


    }
    if (!has_verbs){
        _.each(plural_positions, (pos) => {
            tags[pos].tag = "VBZ"
        })
    }
    return tags
}

const grammer = {
    "description": "A list of English prepositions, sourced from Wikipedia.",
    "prepositions": [
        "aboard",
        "about",
        "above",
        "absent",
        "across",
        "after",
        "against",
        "along",
        "alongside",
        "amid",
        "amidst",
        "among",
        "amongst",
        "around",
        "as",
        "astride",
        "at",
        "atop",
        "before",
        "afore",
        "behind",
        "below",
        "beneath",
        "beside",
        "besides",
        "between",
        "beyond",
        "by",
        "circa",
        "despite",
        "down",
        "during",
        "except",
        "for",
        "from",
        "in",
        "inside",
        "into",
        "less",
        "like",
        "minus",
        "near",
        "nearer",
        "nearest",
        "notwithstanding",
        "of",
        "off",
        "on",
        "onto",
        "opposite",
        "outside",
        "over",
        "past",
        "per",
        "save",
        "since",
        "through",
        "throughout",
        "to",
        "toward",
        "towards",
        "under",
        "underneath",
        "until",
        "up",
        "upon",
        "upside",
        "versus",
        "via",
        "with",
        "within",
        "without",
        "worth",
        "according to",
        "adjacent to",
        "ahead of",
        "apart from",
        "as of",
        "as per",
        "as regards",
        "aside from",
        "astern of",
        "back to",
        "because of",
        "close to",
        "due to",
        "except for",
        "far from",
        "inside of",
        "instead of",
        "left of",
        "near to",
        "next to",
        "opposite of",
        "opposite to",
        "out from",
        "out of",
        "outside of",
        "owing to",
        "prior to",
        "pursuant to",
        "rather than",
        "regardless of",
        "right of",
        "subsequent to",
        "such as",
        "thanks to",
        "up to",
        "as far as",
        "as opposed to",
        "as soon as",
        "as well as",
        "at the behest of",
        "by means of",
        "by virtue of",
        "for the sake of",
        "in accordance with",
        "in addition to",
        "in case of",
        "in front of",
        "in lieu of",
        "in place of",
        "in point of",
        "in spite of",
        "on account of",
        "on behalf of",
        "on top of",
        "with regard to",
        "with respect to",
        "with a view to"
    ]
}


function getPositions(tags, type){
    let positions = [];
    for(var i in tags){
        let tag = tags[i];
        if (type.indexOf(tag.tag) !== -1){
            positions.push(i)
        }
    }
    return positions
}

function isNoun(tag){
    return nouns.indexOf(tag) !== -1
}

function isVerb(tag){
    return verbs.indexOf(tag) !== -1
}

function isPrep(tag){
    return prepositions.indexOf(tag) !== -1
}

function get_type(tag){
    let type = null;
    if (isNoun(tag)){
        type = "noun"
    }
    if (isVerb(tag)){
        type = "verb"
    }
    if (isPrep(tag)){
        type = "preposition"
    }
    return type
}

function collapse(tags){
    let new_tags = [];
    let ns = [];
    let vs = [];
    let ps = [];
    let cur_tag = null;
    let cur_type = null;
    for (var i = 0; i < tags.length; i++){
        let tag = tags[i];
        let next_tag = null;
        if (i + 1 < tags.length - 1){
            next_tag = tags[i + 1]
        }
        if (tag.word == "to"){
            if (next_tag !== null){
                if (next_tag.word == "be"){
                    tag.tag = "VBZ"
                }
            }
        }
        if (i == 0){
            cur_tag = tag;
            cur_type = get_type(tag.tag);
        }
        else {
            let type = get_type(tag.tag)
            if ((type == null && cur_type !== "preposition") || type == cur_type){
                if (next_tag !== null){
                    if (tag.tag == "CC"){
                        if (get_type(next_tag.tag) !== "verb"){
                            cur_tag.word += ` ${tag.word}`
                        }
                    }
                    else {
                        cur_tag.word += ` ${tag.word}`
                    }

                }
                else {
                    cur_tag.word += ` ${tag.word}`
                }

            }
            else {
                if (cur_type !== null){
                    new_tags.push(cur_tag)
                }

                cur_tag = tag;
                cur_type = get_type(tag.tag);
            }
        }
        if (i == tags.length - 1){
            if (cur_tag !== null){
                if (cur_type !== null){
                    new_tags.push(cur_tag)
                }
            }
        }

    }

    return new_tags
}


export const expand_command = (command, domains) =>{

    let objs = [];
    let obj = {
        subject: null,
        verb: null,
        what: null,
        preposition: null,
        target: null,
        decision: null,
        break: false,
        type: "Process"
    }
    let break_val = false;
    let type = "Process";
    command = command.trim()

    if (command.endsWith("!")){
        break_val = true;
        command = command.slice(0, -1).trim()
    }
    if (command.includes("->")){
        type = command.split("->")[1].trim()
        command = command.split("->")[0].trim()
    }

    var tags = getTags(command)

    var collapsed = collapse(tags)

    let last_subject = null;
    let last_what = null;

    let obj_copy = {...obj}
    _.each(collapsed, (tag) => {
        let type = get_type(tag.tag);
        if (type == "noun"){
            if (obj.subject == null){
                obj.subject = tag.word
            }
            else if (domains.indexOf(tag.word) !== -1){
                obj.target = tag.word
            }
            else if (obj.what == null){
                obj.what = tag.word
                last_subject = obj.subject
                last_what = obj.what
                objs.push(obj)
                obj = obj_copy
            }
            else {

            }
        }
        else if (type == "verb"){
            obj.verb = tag.word
            if (obj.subject == null){
                obj.subject = last_subject
            }
        }
        else if (type == "preposition"){
            obj.preposition = tag.word
            if (obj.subject == null){
                obj.subject = last_what
                if (obj.verb == null){
                    obj.verb = obj.preposition

                }
            }
        }

    })
    // objs.push(obj)
    // obj = obj_copy

    objs = _.map(objs, (obj, i) => {
        if (i == objs.length - 1){
            obj.type = type
            obj.break = break_val
        }
        return obj
    })

    return objs
}


function traverse_statements(statements, domains){
    let new_statements = [];
    _.each(statements, (statement) => {
        _.forOwn(statement, (val, key) => {
            if (key == "command"){
                let commands = expand_command(val, domains)
                _.each(commands, (command) => {
                    let statement_copy = {...statement}
                    command.lineId = statement.lineId
                    statement_copy["command"] = command
                    new_statements.push(
                        statement_copy
                    )
                })
            }

            if (key == "options"){

                traverse(val, domains)
            }
        })
    })
    return new_statements
}


const traverse = (data, domains) => {
    _.each(data, (obj) => {
        let statements = traverse_statements(
            obj.statements,
            domains
        )
        obj.statements = statements
    })
    return data
}

const easy_expand = (data) => {
    let lines = data.split("\n")
    // alert(lines.length)
    let domains = []

    let commands = []
    let d = 0
    let c = 0
    let o = 0
    let option = {}
    let options = []
    let tags = []
    let counter = 1
    for (var i=0; i<lines.length; i++){

        let line = lines[i]
        tags = [];
        if (line.includes("-> [")){
            tags = _.map(line.split("-> [")[1].split("]")[0].split(","), (x)=>{
                return x.trim()
            })
        }

        // alert(line)
        if (line == "domains:"){
            d = 1
            c = 0

        }
        else if (line == "commands:"){
            c = 1
            d = 0

        }
        else {

            if (d > 0){
                domains.push(line)

            }
            if (c > 0){
                if (line.trim().endsWith(":")){

                    if ("statements" in option){
                        // alert(line)
                        options.push(option)
                    }
                    o = 1;
                    option.name = line.trim().replace(":", "")
                    option.statements = []

                }
                else if (o > 0){
                    if (line.trim().startsWith("-")){
                        // alert(line)
                        option.statements.push(
                            {
                                lineId: counter,
                                command: line.replace("-", "").trim(),
                                tags: tags
                            }
                        )
                    }
                    else {
                        options.push(option)
                        commands[commands.length - 1].options = options
                        if (line.trim()){
                            commands.push(
                                {
                                    lineId: counter,
                                    command: line,
                                    tags: tags
                                }
                            )
                        }

                        o = 0
                        // prompt("test", JSON.stringify(commands, null, 4))
                    }

                }

                else {
                    if (line.trim()){
                        commands.push(
                            {
                                lineId: counter,
                                command: line,
                                tags: tags
                            }
                        )


                    }


                }

            }

        }



        counter += 1;


    }

    // alert("counter: " + counter.toString())
    window.localStorage.setItem('counter', JSON.stringify(counter, null, 4));

    let newobj = [
        {
            statements: commands,
            timestep: 0,
            domains: domains
        }
    ]


    // fs.writeFile('test.json', JSON.stringify(newobj, null, 4));
    // prompt("test", JSON.stringify(newobj, null, 4))

    // window.localStorage.setItem('tempy1', JSON.stringify(newobj, null, 4));

    let res = traverse(newobj, domains)

    // prompt("test", JSON.stringify(res, null, 4))

    window.localStorage.setItem('tempy2', JSON.stringify(res, null, 4));
    return res

}


export const expand = (data) => {
    if (typeof(data) == "string"){
        return easy_expand(data)
    }
    let data_copy = _.cloneDeep(data);
    let res = traverse(data_copy.scenes, data_copy.domains);
    // prompt("test", JSON.stringify(res, null, 4))

    return res
}
