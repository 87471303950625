import React from 'react';
import { render } from 'react-dom';
import _ from 'lodash'
import LayoutFlow from "./Flow";


class MyLayoutFlow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        sequence: {},
        styleConfig: {},
        filterConfig: {},
        domains: [],
        focusNodeInput: -1

    }


  }
  componentDidMount(){

      this.setState({
          sequence: this.props.sequence,
          styleConfig: this.props.styleConfig,
          filterConfig: this.props.filterConfig,
          domains: this.props.domains,
          focusNodeInput: this.props.focusNodeInput
      })

  }
  componentWillReceiveProps(nextProps){
      if (nextProps.focusNodeInput !== this.state.focusNodeInput){

          this.setState({
              focusNodeInput: nextProps.focusNodeInput
          }, ()=>{
              // alert("MyLayoutFlow: " + this.state.focusNodeInput.toString())
          })
      }
      if (nextProps.sequence !== this.state.sequence){
          this.setState({
              sequence: nextProps.sequence,
              styleConfig: nextProps.styleConfig,
              filterConfig: nextProps.filterConfig
          })
      }

      if (nextProps.styleConfig !== this.state.styleConfig){
          this.setState({
              // sequence: nextProps.sequence,
              styleConfig: nextProps.styleConfig,
              // filterConfig: nextProps.filterConfig
          })
      }

      if (nextProps.filterConfig !== this.state.filterConfig){
          this.setState({
              // sequence: nextProps.sequence,
              filterConfig: nextProps.filterConfig,
              // filterConfig: nextProps.filterConfig
          })
      }

      if (nextProps.domains !== this.state.domains){
          this.setState({
              domains: nextProps.domains,
              // styleConfig: nextProps.styleConfig,
              // filterConfig: nextProps.filterConfig
          })
      }
          // else {
          //     // this.props.handleFilterChange(this.state);
          // }
          console.log("styleConfig:", nextProps.styleConfig)

  }

  // <LayoutFlow sequence={this.state.sequence} domains={this.state.domains} styleConfig={this.state.styleConfig} filterConfig={this.state.filterConfig} />

  render() {
      let data = {
          sequence: this.state.sequence,
          domains: this.state.domains,
          styleConfig: this.state.styleConfig,
          filterConfig: this.state.filterConfig
      }
    return (

        <LayoutFlow focusNodeInput={this.state.focusNodeInput} data={data} />


    );
  }
}

export default MyLayoutFlow;
