import React, {useState, useCallback, useEffect} from 'react';
import { useStore, useStoreState, useZoomPanHelper, getRectOfNodes} from 'react-flow-renderer';
import _ from 'lodash'

const lerp = (x, y, a) => x * (1 - a) + y * a;
const clamp = (a, min = 0, max = 1) => Math.min(max, Math.max(min, a));
const invlerp = (x, y, a) => clamp((a - x) / (y - x));
const range = (x1, y1, x2, y2, a) => lerp(x2, y2, invlerp(x1, y1, a));


const getDistance = (xA, yA, xB, yB) => {
	var xDiff = xA - xB;
	var yDiff = yA - yB;

	return Math.sqrt(xDiff * xDiff + yDiff * yDiff);
}

const boxToRect = ({ x, y, x2, y2 }) => ({
  x,
  y,
  width: x2 - x,
  height: y2 - y,
});

const rectToBox = ({ x, y, width, height }) => ({
  x,
  y,
  x2: x + width,
  y2: y + height,
});

const getBoundsOfBoxes = (box1, box2) => ({
  x: Math.min(box1.x, box2.x),
  y: Math.min(box1.y, box2.y),
  x2: Math.max(box1.x2, box2.x2),
  y2: Math.max(box1.y2, box2.y2),
});

const getBoundsofRects = (rect1, rect2) =>
  boxToRect(getBoundsOfBoxes(rectToBox(rect1), rectToBox(rect2)));

const ZoomHelper = React.memo(props => {
  const store = useStore();
  const { zoomIn, zoomOut, setCenter, zoomTo, fitBounds } = useZoomPanHelper();
  const [nodeBg, setNodeBg] = useState(props.focusNodeInput);
  const containerWidth = useStoreState((s) => s.width);
  const containerHeight = useStoreState((s) => s.height);
  const [tX, tY, tScale] = useStoreState((s) => s.transform);
  const focusNode = (val) => {
    const { nodes } = store.getState();

    // alert(nodeBg)
    if (nodes.length) {
        const ixs = val.toString().trim().split(",").map((n) => {
            return parseInt(n.trim())
        })

        ixs.sort()
        // var x = 0;
        // var y = 0;
        // const zoom = 10;

        // const ix = parseInt(ixs)
        // const node = nodes[ix];
        const nodeLineIds = nodes.map((n)=>{
            return n.lineId
        })

        // alert(JSON.stringify(ixs))

        // const min = ixs.reduce((a,b) => {
        //     return Math.min(a, b), ixs[0]
        // })
        // const min = ixs.reduce((a,b) => {
        //     return Math.max(a, b), ixs[0]
        // })
        const min = Math.min(...ixs)
        const max = Math.max(...ixs)
        // const closest = nodeLineIds.reduce((a, b) => {
        //     return Math.abs(b - ix) < Math.abs(a - ix) ? b : a;
        // });
        const closestmin = nodeLineIds.reduce((a, b) => {
            return Math.abs(b - min) < Math.abs(a - min) ? b : a;
        });
        const closestmax = nodeLineIds.reduce((a, b) => {
            return Math.abs(b - max) < Math.abs(a - max) ? b : a;
        });

        const filteredNodes = nodes.filter((n) => {
            return n.lineId >= closestmin && n.lineId <= closestmax ;
        })

        // alert(JSON.stringify(filteredNodes))




        const getMinY = () =>{
          return nodes.reduce((min, b) => Math.min(min, b.__rf.position.y + b.__rf.height / 2), nodes[0].__rf.position.y + nodes[0].__rf.height / 2);
        }
        const getMaxY = () =>{
          return nodes.reduce((max, b) => Math.max(max, b.__rf.position.y + b.__rf.height / 2), nodes[0].__rf.position.y + nodes[0].__rf.height / 2);
        }

        const getMinX = () =>{
          return nodes.reduce((min, b) => Math.min(min, b.__rf.position.x + b.__rf.width / 2), nodes[0].__rf.position.x + nodes[0].__rf.width / 2);
        }
        const getMaxX = () =>{
          return nodes.reduce((max, b) => Math.max(max, b.__rf.position.x + b.__rf.width / 2), nodes[0].__rf.position.x + nodes[0].__rf.width / 2);
        }



        let xnodes = 0;
        let ynodes = 0;

        let maxDist = getDistance(getMinX(), getMinY(), getMaxX(), getMaxY());
        let dist = 0;
        let furthestNode = [];
        let smallestx = null;
        let smallesty = null;
        let largestx = null;
        let largesty = null;




        for (var j = 0; j < filteredNodes.length; j++){
            let node = filteredNodes[j];
            let xn = node.__rf.position.x + node.__rf.width / 2
            let yn = node.__rf.position.y + node.__rf.height / 2;
            xnodes += xn
            ynodes += yn

            if (xn < smallestx){
                smallestx = xn
            }
            else if (xn > largestx){
                largestx = xn
            }
            if (yn < smallesty){
                smallesty = yn
            }
            else if (yn > largesty){
                largesty = yn
            }

        }

        dist = getDistance(smallestx, smallesty, largestx, largesty)



        // alert(node.id)
        // alert(JSON.stringify(node))
        const x = xnodes / filteredNodes.length;
        const y = ynodes / filteredNodes.length;




        const z = range(0, maxDist, 0, 100, dist)
        const zoom = range(0, 100, 8, .1, z)

        setCenter(x, y);

        const bb = getRectOfNodes(filteredNodes);
        const viewBB: Rect = {
            x: -tX / tScale,
            y: -tY / tScale,
            width: containerWidth / tScale,
            height: containerHeight / tScale,
          };

          const hasNodes = filteredNodes && filteredNodes.length;

          const boundingRect = hasNodes ? getBoundsofRects(bb, viewBB) : viewBB;
        // alert(JSON.stringify([x, y, zoom]))

        fitBounds(bb, .2)


        let worksheet = {
            dist: dist,
            maxDist: maxDist,
            x: x,
            y: y,
            z: z,
            zoom: zoom,
            filteredNodes: filteredNodes,
            min: min,
            max: max,
            boundingRect: boundingRect,
            bb: bb,
            viewBB: viewBB
        }

        window.localStorage.setItem('worksheet', JSON.stringify(worksheet, null, 4));



      // zoomTo(0.0001)
    }
  };
  useEffect(() => {
  // if (props.sequence !== seq) {
  if (!_.isEqual(props.focusNodeInput, nodeBg)){
    setNodeBg(props.focusNodeInput);
    console.log("data change")

    // alert(props.focusNodeInput.toString() + " " + nodeBg.toString())
    // alert("ZoomHelper: " + nodeBg)
    // focusNode();
    // setElements(getLayoutedElements(getElements(props.data.sequence, props.data.styleConfig, props.data.filterConfig, props.data.domains)));
    // onSave();
    // if (rfInstance) {
    //     // rfInstance.fitView();
    //     const obj = rfInstance.toObject();
    //     // rfInstance.setTransform({ x: 10, y: obj.position[1], zoom: obj.zoom });
    //     onSave();
    // }
    focusNode(props.focusNodeInput.toString());
  }
  }, [props.focusNodeInput]);
  return (
    <div style={{
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        display: "none",
        width: "100%",
        zIndex: 100000
    }}>
        <input id="focusNodeInput" value={nodeBg} onChange={((evt) => {


        })} />
      <button id="focusNodeButton" onClick={focusNode}>focus node</button>
      <button onClick={zoomIn}>zoom in</button>
      <button onClick={zoomOut}>zoom out</button>
      </div>
  );
});

export default ZoomHelper;
