import React from 'react';
import { render } from 'react-dom';
import _ from 'lodash'
import MonacoEditor from 'react-monaco-editor';


class FilterEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        filterText: JSON.stringify({
            show: [],
            hide: [],
            showUntagged: true
        }, null, 4),
        filter: {

        }
    }
    this.handleFilterTextChange = this.handleFilterTextChange.bind(this)
    this.buttonClick = this.buttonClick.bind(this)

  }
  componentDidMount(){
      let filters_json = Object.keys(this.props.sequence).length > 0 ? this.props.sequence.filters_json : {
          show: [],
          hide: [],
          showUntagged: true
      }

      this.setState({
          sequence: this.props.sequence,
          filterText: JSON.stringify(filters_json, null, 4)
      }, ()=>{
          this.props.handleFilterChange(this.state.filter);
      })


  }
  componentWillReceiveProps(nextProps){
      if (nextProps.sequence !== this.state.sequence){
          let filters_json = Object.keys(nextProps.sequence).length > 0 ? nextProps.sequence.filters_json : {
              show: [],
              hide: [],
              showUntagged: true
          }
          if (Object.keys(filters_json).length > 0){
              this.setState({
                  sequence: nextProps.sequence,
                  filterText: JSON.stringify(filters_json, null, 4)
              }, ()=>{
                  this.props.handleFilterChange(this.state.filter);
              })
          }
          else {
              // this.props.handleFilterChange(this.state);
          }
      }
  }
  buttonClick(){
      console.log("buttonClick Filter")
      this.setState({
          filter: JSON.parse(this.state.filterText)
      }, ()=> {
          this.props.handleFilterChange(this.state.filter);
      })

  }

  handleFilterTextChange(evt){
      // alert(evt)
      // console.log("evt.taget.value:", evt.target.value)
      try{
          this.setState({
              filterText: evt
          })
      }
      catch(err){
          console.log(err)
      }

  }
  editorDidMount(editor, monaco) {
    // console.log('editorDidMount', editor);
    editor.focus();
  }


  render() {
      const options = {
        selectOnLineNumbers: true,
        tabSize: 2
      };

    return (
        <div className="StyleEditor">
        {/*
            <div style={{justifyContent: 'center'}} className="StyleEditorRow">
            <button onClick={this.buttonClick}>Save</button>
            </div>

        */}

        <div className="styleItem">

        <h5 style={{color: "white"}}>
            Show Tags
        </h5>

        <MonacoEditor
          width="800"
          height="200"
          language="json"
          theme="myCoolTheme"
          value={this.state.filterText} onChange={this.handleFilterTextChange}
          options={options}

          editorDidMount={this.editorDidMount}
        />



         </div>
        </div>
    );
  }
}

export default FilterEditor;
